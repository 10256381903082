import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Department, DocumentType, StockMovementType, Warehouse } from '../../models/Models';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Grid, Paper, FormControl, InputLabel, MenuItem, Select, ListSubheader, Alert, IconButton, Snackbar } from '@mui/material';
import ButtonList, { Item } from '../../components/ButtonList';

// Ikony
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

export interface DocumentCreateMovementProps {
    open: boolean;
    documentType: DocumentType;
    warehouseId?: number,
    warehouses: Warehouse[];
    departmentId?: number,
    departments: Department[],
    stockMovementTypes?: StockMovementType[];
    keepMounted?: boolean;
    onSelect: (departmentId: number, warehouseId: number, stockMovementType: StockMovementType) => void;
    onClose: (cancel?: boolean) => void;
}

const DocumentCreateMovement = (props: DocumentCreateMovementProps) => {

    const [stockMovementTypes, setStockMovementTypes] = useState<StockMovementType[]>(props.stockMovementTypes ?? []);
    const [items, setItems] = useState<Item[]>([]);
    const [departmentId, setDepartmentId] = useState<number>(props.departmentId ?? 0);
    const [departmentsGrouped, setDepartmentsGrouped] = useState<any[]>([]);
    const [warehouseId, setWarehouseId] = useState<number>(props.warehouseId ?? 0);
    const [warehousesFiltered, setWarehousesFiltered] = useState<Warehouse[]>([]);

    // Príjem (áno / nie)
    const inbound = (props.documentType.stock === 1);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    const [warning, setWarning] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (warning !== undefined) {
            setWarning(undefined);
        }
    }, [departmentId, warehouseId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automaticky získam zoznam prevádzok zo stredísk
    useEffect(() => {
        var items: any[] = [];
        var branchNames = Array.from(new Set(props.departments?.map(item => item.branchName ?? '') ?? []));
        branchNames?.forEach(branchName => {
            items.push(<ListSubheader key={branchName} component="div" disableSticky sx={{ maxHeight: '25px', lineHeight: '25px', background: 'none', fontSize: 'small' }}>{branchName}</ListSubheader>);
            props.departments?.filter(item => item.branchName === branchName)?.forEach(item => items.push(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>));
        });
        setDepartmentsGrouped(items);
    }, [props.departments]);

    // Automaticky odfiltrujem sklady z ktorých môžem vyberať (ak pre daný druh dokladu nie sú povolené, tak je zoznam prázdny)
    useEffect(() => {

        // Odfiltrovaný zoznam
        let filtered: Warehouse[] = [...props.warehouses];

        // Ak je vybrané stredisko, tak môžem zobraziť len sklady, ktoré patria pod dané stredisko
        if (filtered.length > 0 && (departmentId ?? 0) > 0) {
            filtered = filtered.filter(warehouse => (warehouse.departments ?? []).includes((departmentId ?? 0)));
        }

        // Ak je nastavený druh dokladu so skladovým pohybom príjem alebo výdaj, tak môžem zobraziť len vlastné sklady
        if (filtered.length > 0 && ((props.documentType.stock ?? 0) === 1 || (props.documentType.stock ?? 0) === 2)) {
            filtered = filtered.filter(warehouse => (warehouse.type ?? 0) === 0);
        }

        // Ak mám nastavený sklad, ktorý už nefiguruje v zozname, tak ho musím zrušiť
        if (warehouseId > 0 && filtered.find(warehouse => warehouse.id === warehouseId) === undefined) {
            setWarehouseId(0);
        }

        setWarehousesFiltered(filtered);

    }, [props.warehouses, departmentId, props.documentType]);

    // Načítam zoznam typov
    const loadStockMovementTypes = () => {
        if (stockMovementTypes.length > 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'stockmovementtype')
            .then(response => setStockMovementTypes(response.data as StockMovementType[]));
    };
    useEffect(() => loadStockMovementTypes(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Vygenerujem zoznam tlačidiel pre výber
    useEffect(() => {
        const items: Item[] = [];
        stockMovementTypes.filter(movementType => movementType.type === props.documentType.stock).forEach((movementType, index) => {
            items.push({
                key: movementType,
                title: movementType.name ?? '-',
                icon: inbound ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />,
                divider: true
            });
        });
        if (items.length > 0)
            items[items.length - 1].divider = false;
        setItems(items);
    }, [stockMovementTypes, props.documentType]);

    const handleSelect = (stockMovementType: StockMovementType) => {
        setWarning(undefined);
        if (departmentId === 0) {
            setWarning('Musíte vybrať stredisko!');
            return;
        }
        if (warehouseId === 0) {
            setWarning('Musíte vybrať sklad!');
            return;
        }
        props.onSelect(departmentId, warehouseId, stockMovementType);
        props.onClose();
    }

    return (
        <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(true); } }}>
            <Box>
                <DialogTitle>{inbound ? 'Príjem na sklad' : 'Výdaj zo skladu'}</DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                        <Grid item xs={6}>
                            <FormControl required margin="dense" variant="outlined" fullWidth>
                                <InputLabel id="document-create-department">Stredisko (oddelenie)</InputLabel>
                                <Select labelId="document-create-department" label="Stredisko (oddelenie)" value={departmentId ?? 0} onChange={(e) => { setDepartmentId(e.target.value as number) }}>
                                    <MenuItem key={0} value={0}>-</MenuItem>
                                    {departmentsGrouped.map(item => item)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel required id="document-create-warehouse">Sklad</InputLabel>
                                <Select labelId="document-create-warehouse" label="Sklad" value={warehouseId ?? 0} onChange={(e) => { setWarehouseId(e.target.value as number) }}>
                                    <MenuItem key={0} value={0}>-</MenuItem>
                                    {warehousesFiltered?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Paper>
                                <ButtonList activedKey={undefined} items={items ?? []} onClick={(e) => handleSelect(e.key as StockMovementType)} />
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(true)}>Späť</Button>
                </DialogActions>
            </Box>

            <Snackbar open={warning !== undefined} autoHideDuration={10000} onClose={() => setWarning(undefined)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <Alert sx={{ mt: 1 }} variant="filled" severity={'error'} action={
                    <IconButton color="inherit" size="small" onClick={() => setWarning(undefined)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                >{warning}</Alert>
            </Snackbar>

        </Dialog >
    )
}

export default DocumentCreateMovement;