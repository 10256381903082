import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../utility/Number';

// Modely
import { Customer, Domain, PriceList, CustomerGroup, CustomerAddress, Country, CustomerLite, CustomerContact, CustomersFilter, CustomerBank, CustomerBase, ResponseData, Modules, Language, Shipment, Payment } from '../../models/Models';

// Utility
import { PasswordStrength, PasswordStrengthType } from '../../utility/PasswordStrength';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Box, Snackbar, IconButton, FormControl, InputLabel, MenuItem, Select, InputAdornment, TextField, Tooltip, Typography, FormControlLabel, Switch, Radio, RadioGroup, Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableRow, ButtonGroup, Divider, ListItemIcon, Menu } from '@mui/material';
import Grid from '@mui/material/Grid';
import CustomerCreateAddress from './CustomerCreateAddress';
import CustomerCreateBank from './CustomerCreateBank';
import CustomFieldValues from '../settings/custom_field/CustomFieldValues';
import CustomersDialog, { CustomersDialogProps } from './CustomersDialog';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import NumbersIcon from '@mui/icons-material/Numbers';
import SchoolIcon from '@mui/icons-material/School';
import PercentIcon from '@mui/icons-material/Percent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';
import EngineeringIcon from '@mui/icons-material/Engineering';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import ContactsIcon from '@mui/icons-material/Contacts';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import TodayIcon from '@mui/icons-material/Today';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Customer => ({
    id: 0,
    domainId: 0,
    actived: true,
    phone: '',
    email: '',
    customerGroupId: 0,
    priceListId: 0,
    discountLoyalty: 0,
    discountAdditional: 0,
    discountMaximum: 0,
    discountCurrent: 0,
    note: '',
    title: '',
    person: false,
    firstname: '',
    lastname: '',
    company: '',
    regId: '',
    taxId: '',
    vatId: '',
    city: '',
    street: '',
    houseNumber: '',
    postcode: '',
    countryId: 0,
    addresses: []
});

// Možnosti pri uložení
export enum CustomerCreateSaveAction {
    SaveAndContinue,
    SaveAndNew,
    SaveAndNewFromCopy
}

// Vstupné parametre
export interface CustomerCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    disableSaveAction?: boolean;        // Vypne rozšírené akcie po uložení
    onSave?: (id?: number, action?: CustomerCreateSaveAction) => void;
    onClose: () => void;
}

const CustomerCreate = (props: CustomerCreateProps) => {

    // Referencie
    const refPasswordVerify = useRef<HTMLInputElement>(null);

    // Stav
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Customer>(EmptySource());
    const [domains, setDomains] = useState<Domain[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [shipments, setShipments] = useState<Shipment[]>([]);
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);
    const [customerRegister, setCustomerRegister] = useState<CustomerBase>({});
    const [saveMenuEl, setSaveMenuEl] = useState<null | HTMLElement>(null);

    // Sledovanie zmien - aktivované oneskorene, aby sa stihol zmeniť stav "source"
    const [sourceChanged, setSourceChanged] = useState<boolean | undefined>(undefined);
    const setSourceStartWatch = () => { setTimeout(() => { setSourceChanged(false); }, 250); };
    const setSourceStopWatch = () => { setSourceChanged(undefined); };
    useEffect(() => {
        if (sourceChanged === false) {
            setSourceChanged(true);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Výber partnera
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[], argument: any) => {
            if (customers.length > 0) {
                setSource(prev => {
                    if (argument === 'person') {
                        // Vyberám kontaktné osoby
                        const changed: CustomerContact[] = [...prev.contacts ?? []];
                        customers.forEach(contact => {
                            if (contact.person === true && contact.id !== props.id) {
                                if (changed.find(item => item.id === contact.id) === undefined) {
                                    changed.push({ id: contact.id, name: (contact.name ?? ''), phone: contact.phone, email: contact.email });
                                }
                            }
                        });
                        return ({ ...prev, contacts: changed });
                    }
                    else {
                        // Vyberám nadradenú firmu
                        return ({ ...prev, parentCustomerId: customers[0].id ?? 0, other: { ...prev.other, parentCustomerName: customers[0].name ?? '' } });
                    }
                });
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });

    // Zobrazenie zákazníkov
    const handleCustomersOpen = (argument: any, filter: CustomersFilter) => {
        setCustomersDialog(prev => ({ ...prev, open: true, argument: argument, filter: filter }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };

    // Reset formuláru
    const handleReset = (keepId?: boolean) => {
        setCustomerRegister({});
        setSource(prev => ({
            ...EmptySource(),
            id: (keepId ?? false) === true ? prev.id : 0,
            domainId: domains?.find(domain => domain.default)?.id ?? 0,
            customerGroupId: customerGroups.find(customerGroup => customerGroup.system)?.id ?? 0
        }));
        setSourceStartWatch();
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();
        if ((props.id ?? 0) === 0) {
            setSourceStopWatch();
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customer/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                        // Vynulujem aj ID relačných záznamov (inak by sa preniesli zo záznamu na záznam)
                        var addressesCount = (response.data.addresses.length ?? 0);
                        if (addressesCount > 0) {
                            const addresses = response.data.addresses as CustomerAddress[];
                            response.data.addresses = [...addresses.map(address => { return { ...address, id: 0 } })];
                        }
                    }
                    setSource(response.data as Customer);
                    setSourceStartWatch();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zoznam jazykov
    const loadLanguages = () => {
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Načítam zoznam dopráv
    const loadShipments = () => {
        axios
            .get(AppConfig.ApiUri + 'shipment')
            .then(response => {
                setShipments(response.data);
            });
    };
    useEffect(() => loadShipments(), []);

    // Načítam zoznam platieb
    const loadPayments = () => {
        axios
            .get(AppConfig.ApiUri + 'payment')
            .then(response => {
                setPayments(response.data);
            });
    };
    useEffect(() => loadPayments(), []);

    // Načítam zákazníckych skupín
    const loadCustomerGroups = () => {
        axios
            .get(AppConfig.ApiUri + 'customergroup')
            .then(response => {
                setCustomerGroups(response.data);
            });
    };
    useEffect(() => loadCustomerGroups(), []);

    // Načítam zoznam cenníkov
    const loadPriceLists = () => {
        axios
            .get(AppConfig.ApiUri + 'pricelist')
            .then(response => {
                setPriceLists(response.data.filter((pricelist: PriceList) => pricelist.actived));
            });
    };
    useEffect(() => loadPriceLists(), []);

    // Načítam zoznam krajín
    const loadCountries = () => {
        axios
            .get(AppConfig.ApiUri + 'country')
            .then(response => {
                setCountries(response.data.filter((country: Country) => country.actived));
            });
    };
    useEffect(() => loadCountries(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => {
            const changed = { ...prev, [property]: value };
            // Pri zmene v zľavách automaticky prepočítam aktuálnu zľavu
            if (property === 'discountAdditional' || property === 'discountMaximum') {
                changed.discountCurrent = (changed.discountLoyalty ?? 0) + (changed.discountAdditional ?? 0);
                if ((changed.discountMaximum ?? 0) > 0 && changed.discountCurrent > (changed.discountMaximum ?? 0)) {
                    changed.discountCurrent = changed.discountMaximum;
                }
            }
            return changed;
        });
    }

    // Otvorenie URL adresy
    const openUrl = (url: string, target?: string) => {
        var link = document.createElement("a");
        link.setAttribute("href", url);
        if (target !== undefined) {
            link.setAttribute("target", target);
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Vyhľadanie adresy, nový email, volať..
    const handleOpenMap = (address: string) => {
        openUrl('http://maps.google.com/?q=' + address, '_blank');
    }
    const handleOpenCall = (phone: string) => {
        openUrl('tel:' + phone);
    }
    const handleOpenEmail = (email: string) => {
        openUrl('mailto:' + email);
    }
    const handleOpenGoogle = (search: string) => {
        openUrl('https://www.google.com/search?q=' + search, '_blank');
    }
    const handleOpenORSR = (regId: string) => {
        openUrl('https://orsr.sk/hladaj_ico.asp?ICO=' + regId, '_blank');
    }
    const handleOpenFinstat = (regId: string) => {
        openUrl('https://finstat.sk/' + regId, '_blank');
    }
    const handleOpenFacebook = (name: string) => {
        openUrl('https://www.facebook.com/search/top/?q=' + name, '_blank');
    }
    const handleOpenLinkedIn = (name: string) => {
        openUrl('https://www.linkedin.com/search/results/all/?keywords=' + name, '_blank');
    }
    const handleRegisterFind = () => {
        if ((source.regId ?? '').length < 5) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customer/register/' + source.regId)
            .then(response => {
                if (response.data !== null) {
                    setCustomerRegister(response.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // Vloženie, upravenie a vymazanie doručovacej adresy
    const handleAddressesAdd = () => {
        setSource(prev => ({ ...prev, addresses: [...prev.addresses ?? [], {}] }));
    }
    const handleAddressesChange = (index: number, property: string, value: any) => {
        setSource(prev => {
            const addresses = prev.addresses ?? [];
            addresses[index] = { ...addresses[index], [property]: value };
            return ({ ...prev, addresses: [...addresses] });
        });
    }
    const handleAddressesDelete = (index: number) => {
        setSource(prev => {
            const addresses = prev.addresses ?? [];
            return ({ ...prev, addresses: [...addresses.filter(item => item !== addresses[index]) ?? []] });
        });
    }

    // Vloženie, upravenie a vymazanie bankového účtu
    const handleBankAdd = () => {
        setSource(prev => {
            // Prvý bankový účet automaticky predvolím ako hlavný a vyplním východzí názov
            var newBank = ((prev.banks?.length ?? 0) === 0 ? { main: true, name: 'Hlavné bankové spojenie' } : {}) as CustomerBank;
            return ({ ...prev, banks: [...prev.banks ?? [], newBank] });
        });
    }
    const handleBankChange = (index: number, property: string, value: any) => {
        setSource(prev => {
            const banks = prev.banks ?? [];
            banks[index] = { ...banks[index], [property]: value };

            // Prepínač - hlavný účet
            if (property === 'main' && value === true) {
                banks?.every((item, itemIndex) => {
                    if (itemIndex !== index) {
                        banks[itemIndex] = { ...banks[itemIndex], [property]: false }
                    }
                    return true;
                });
            }
            return ({ ...prev, banks: [...banks] });
        });
    }
    const handleBankDelete = (index: number) => {
        setSource(prev => {
            const banks = prev.banks ?? [];
            return ({ ...prev, banks: [...banks.filter(item => item !== banks[index]) ?? []] });
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event?: FormEvent<HTMLFormElement>, action?: CustomerCreateSaveAction) => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }

        if ((source.password?.length ?? 0) > 0) {
            const password = source.password ?? '';
            const passwordVerify = refPasswordVerify.current?.value ?? '';

            if (password !== passwordVerify) {
                setWarning({ ...EmptyWarning, name: 'password', text: 'Heslá sa nezhodujú!' });
                return;
            }
            if (PasswordStrength(password) !== PasswordStrengthType.Strong &&
                PasswordStrength(password) !== PasswordStrengthType.Medium) {
                setWarning({ ...EmptyWarning, name: 'password', text: 'Heslo musí mať minimálne 8 znakov a obsahovať veľké písmeno, malé písmeno a číslicu!' });
                return;
            }
        }

        setWarning(EmptyWarning);
        setLoading(true);

        if ((source.person ?? false) === true) {
            // Premažem firemné údaje
            source.company = '';
            source.regId = '';
            source.taxId = '';
            source.vatId = '';
            source.contacts = [];
        }
        else {
            // Premažem osobné údaje
            source.department = '';
            source.classification = '';
            source.parentCustomerId = 0;
        }

        axios
            .post(AppConfig.ApiUri + 'customer', source)
            .then(response => {
                if (response.data !== undefined) {
                    const responseData = response.data as ResponseData
                    props.onClose();
                    if (props.onSave) {
                        props.onSave((responseData.data ?? 0) as number, action);
                    }
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => {
                if (r !== 'backdropClick') {
                    if (sourceChanged !== true) {
                        props.onClose();
                        return;
                    }
                    setConfirm(prev => ({
                        ...prev, open: true, title: 'Neuložené zmeny', children: 'Skutočne chcete zavrieť okno bez uloženia zmien?', onConfirm: () => {
                            setConfirm(prev => ({ ...prev, open: false }));
                            props.onClose();
                        }
                    }));
                }
            }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave} noValidate>
                    <DialogTitle sx={{ px: 3, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {((props?.id ?? 0) > 0 ? 'Partner' : 'Nový partner')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={5}>
                                <FormControlLabel control={<Switch checked={source.actived ?? false} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={7}>
                                <RadioGroup row name="person" value={source.person ?? false} onChange={(e) => { handleChange(e.target.name, (e.target.value === 'true')); }} sx={{ justifyContent: 'flex-end' }} >
                                    <FormControlLabel value="true" control={<Radio />} label="Osoba" />
                                    <FormControlLabel value="false" control={<Radio />} label="Firma" />
                                </RadioGroup>
                            </Grid>

                            {(source.person ?? false) === false && (
                                <>
                                    <Grid item xs={12}>
                                        <Typography textAlign="center" variant="h6" gutterBottom>Firemné údaje</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField margin="dense" name="company" label="Názov firmy" fullWidth variant="outlined" autoComplete="off" value={source.company ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => ((source.person ?? false) === false && (props.autoFocus === 'name' || props.autoFocus === 'company' || props.autoFocus === undefined)) && setFocus(input)}
                                            inputProps={{ maxLength: 50 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CorporateFareIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField margin="dense" name="regId" label="IČO" fullWidth variant="outlined" autoComplete="off" value={source.regId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'regId') && setFocus(input)}
                                            inputProps={{ maxLength: 15 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <NumbersIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField margin="dense" name="taxId" label="DIČ" fullWidth variant="outlined" autoComplete="off" value={source.taxId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'taxId') && setFocus(input)}
                                            inputProps={{ maxLength: 18 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <NumbersIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <TextField margin="dense" name="vatId" label="IČDPH" fullWidth variant="outlined" autoComplete="off" value={source.vatId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => (props.autoFocus === 'vatId') && setFocus(input)}
                                            inputProps={{ maxLength: 20 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <NumbersIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <FormControl margin="dense" variant="outlined" fullWidth>
                                            <InputLabel id="filter-vattype">Nastavenie DPH</InputLabel>
                                            <Select labelId="filter-vattype" label="Nastavenie DPH" value={source.vatType ?? 0} name="vatType" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                inputRef={input => props.autoFocus === 'vatType' && setFocus(input)}>
                                                <MenuItem key={0} value={0}>-</MenuItem>
                                                <MenuItem key={1} value={1}>Neplatca DPH</MenuItem>
                                                <MenuItem key={2} value={2}>Platca DPH</MenuItem>
                                                <MenuItem key={3} value={3}>Osoba registrovaná pre DPH</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {((source.regId ?? '').length > 5 || (source.company ?? '').length > 2) && (
                                        <Grid item xs={12}>
                                            {(source.regId ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleRegisterFind()} startIcon={<SearchIcon />}>Vyhľadať</Button>}
                                            {(source.company ?? '').length > 2 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenGoogle(source.company ?? '')} startIcon={<GoogleIcon />}>Google</Button>}
                                            {(source.regId ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenORSR(source.regId ?? '')} startIcon={<BadgeIcon />}>ORSR</Button>}
                                            {(source.regId ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenFinstat(source.regId ?? '')} startIcon={<BadgeIcon />}>Finstat</Button>}
                                        </Grid>
                                    )}

                                    {(customerRegister.regId ?? '').length > 5 && (
                                        <Grid item xs={12} mt={1}>
                                            <Alert severity="success" onClose={() => setCustomerRegister({})}>
                                                <strong>{customerRegister.company}</strong><br />
                                                {(customerRegister.street ?? '').length > 0 ? <>{(customerRegister.street ?? '') + ' ' + (customerRegister.houseNumber ?? '') + ', '}</> : null}
                                                {(customerRegister.city ?? '').length > 2 ? <>{customerRegister.city}</> : null}
                                                {(customerRegister.postcode ?? '').length > 0 ? <>{' ' + customerRegister.postcode}</> : null}
                                                <br />
                                                {(customerRegister.regId ?? '').length > 0 ? <>{'IČO: ' + customerRegister.regId}</> : null}
                                                {(customerRegister.vatId ?? '').length > 0 ? <>{', DIČ: ' + customerRegister.taxId}</> : null}
                                                {(customerRegister.taxId ?? '').length > 0 ? <>{', IČDPH: ' + customerRegister.vatId}</> : null}
                                                <br />
                                                <Button size="small" color="success" variant="contained" sx={{ mt: 1 }} onClick={() => {
                                                    setSource(prev => ({
                                                        ...prev,
                                                        company: customerRegister.company,
                                                        city: customerRegister.city,
                                                        street: customerRegister.street,
                                                        houseNumber: customerRegister.houseNumber,
                                                        postcode: customerRegister.postcode,
                                                        regId: customerRegister.regId,
                                                        taxId: customerRegister.taxId,
                                                        vatId: customerRegister.vatId,
                                                        countryId: customerRegister.countryId
                                                    }));
                                                    setCustomerRegister({});
                                                }}>Použiť</Button>
                                            </Alert>
                                        </Grid>
                                    )}
                                </>
                            )}

                            <Grid item xs={12} mt={(source.person ?? false) === true ? 0 : 3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>{(source.person ?? false) === true ? 'Osobné údaje' : 'Kontaktná osoba'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextField margin="dense" name="title" label="Titul" fullWidth variant="outlined" autoComplete="off" value={source.title ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'title') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SchoolIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField margin="dense" name="firstname" label="Meno" fullWidth variant="outlined" autoComplete="off" value={source.firstname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => ((source.person ?? false) === true && (props.autoFocus === 'name' || props.autoFocus === 'firstname' || props.autoFocus === undefined)) && setFocus(input)}
                                    inputProps={{ maxLength: 35 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField margin="dense" name="lastname" label="Priezvisko" fullWidth variant="outlined" autoComplete="off" value={source.lastname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'lastname') && setFocus(input)}
                                    inputProps={{ maxLength: 35 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField margin="dense" name="email" label="Email" fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'email') && setFocus(input)}
                                    inputProps={{ maxLength: 120 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="phone" label="Telefón" fullWidth variant="outlined" autoComplete="off" value={source.phone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'phone') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {((source.phone ?? '').length > 5 || (source.email ?? '').length > 0 || ((source.firstname ?? '').length > 0 && (source.lastname ?? '').length > 0)) && (
                                <Grid item xs={12}>
                                    {(source.phone ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenCall(source.phone ?? '')} startIcon={<PhoneIcon />}>Volať</Button>}
                                    {(source.email ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenEmail(source.email ?? '')} startIcon={<EmailIcon />}>Email</Button>}
                                    {(source.firstname ?? '').length > 0 && (source.lastname ?? '').length > 0 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenFacebook((source.firstname ?? '') + ' ' + (source.lastname ?? ''))} startIcon={<FacebookIcon />}>Facebook</Button>}
                                    {(source.firstname ?? '').length > 0 && (source.lastname ?? '').length > 0 && <Button variant="outlined" size="small" sx={{ mt: 1, mr: 1 }} onClick={() => handleOpenLinkedIn((source.firstname ?? '') + ' ' + (source.lastname ?? ''))} startIcon={<LinkedInIcon />}>LinkedIn</Button>}
                                </Grid>
                            )}

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Fakturačná adresa</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField margin="dense" name="street" label="Ulica" fullWidth variant="outlined" autoComplete="off" value={source.street ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'street') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="houseNumber" label="Číslo domu" fullWidth variant="outlined" autoComplete="off" value={source.houseNumber ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'houseNumber') && setFocus(input)}
                                    inputProps={{ maxLength: 15 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-country">Krajina</InputLabel>
                                    <Select labelId="filter-country" label="Krajina" value={source.countryId ?? 0} name="countryId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'countryId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {countries?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={8} md={4}>
                                <TextField margin="dense" name="city" label="Mesto" fullWidth variant="outlined" autoComplete="off" value={source.city ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'city') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4}>
                                <TextField margin="dense" name="postcode" label="PSČ" fullWidth variant="outlined" autoComplete="off" value={source.postcode ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'postcode') && setFocus(input)}
                                    inputProps={{ maxLength: 15 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(source.street ?? '').length > 0 && (source.houseNumber ?? '').length > 0 && (source.city ?? '').length > 0 && <Grid item xs={12}>
                                <Button variant="outlined" size="small" sx={{ mt: 1 }} onClick={() => handleOpenMap((source.street ?? '') + ' ' + (source.houseNumber ?? '') + ' ' + (source.city ?? ''))} startIcon={<LocationOnIcon />}>Mapa</Button>
                            </Grid>}

                            <Grid item xs={12} mt={2}>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('basic')} onChange={() => handleToggleAccordion('basic')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Základné údaje</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="customer-create-group">Zákaznícka skupina</InputLabel>
                                                    <Select required labelId="customer-create-group" label="Zákaznícka skupina" value={source.customerGroupId ?? 0} name="customerGroupId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => { props.autoFocus === 'customerGroupId' && setFocus(input) }}>
                                                        {customerGroups?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="customer-create-pricelist">Cenník</InputLabel>
                                                    <Select labelId="customer-create-pricelist" label="Cenník" value={source.priceListId ?? 0} name="priceListId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => { props.autoFocus === 'priceListId' && setFocus(input) }}>
                                                        <MenuItem key={0} value={0}>-</MenuItem>
                                                        {priceLists?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} my={1}>
                                                <Alert severity="info">
                                                    Pokiaľ zákazník nemá nastavený konkrétny cenník, tak bude automaticky použitý cenník nastavený v jeho zákazníckej skupine.
                                                    Ak ani zákaznícka skupina nemá nastavený konkrétny cenník, tak bude automaticky použitý predvolený cenník z jeho domény.
                                                    Pokiaľ nie je nastavený žiadny cenník, tak bude použitá základná cena.
                                                </Alert>
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="customer-create-language">Jazyk</InputLabel>
                                                    <Select labelId="customer-create-language" label="Jazyk" value={source.languageCode ?? ''} name="languageCode" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'languageCode' && setFocus(input)}>
                                                        <MenuItem key={0} value=''>-</MenuItem>
                                                        {languages?.map(item => <MenuItem key={item.id} value={item.code}>{item.name}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="customer-create-payment">Preferovaná platba</InputLabel>
                                                    <Select labelId="customer-create-payment" label="Preferovaná platba" value={source.paymentId ?? ''} name="paymentId" onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                                        inputRef={input => props.autoFocus === 'paymentId' && setFocus(input)}>
                                                        <MenuItem key={0} value=''>-</MenuItem>
                                                        {payments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="customer-create-shipment">Preferovaná doprava</InputLabel>
                                                    <Select labelId="customer-create-shipment" label="Preferovaná doprava" value={source.shipmentId ?? ''} name="shipmentId" onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                                        inputRef={input => props.autoFocus === 'shipmentId' && setFocus(input)}>
                                                        <MenuItem key={0} value=''>-</MenuItem>
                                                        {shipments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={8}>
                                                <TextField margin="dense" name="website" label="Webstránka" fullWidth variant="outlined" autoComplete="off" value={source.website ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => (props.autoFocus === 'website') && setFocus(input)}
                                                    inputProps={{ maxLength: 255 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <InsertLinkIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <TextField fullWidth margin="dense" name="dueDays" type="number" label="Splatnosť (dni)" variant="outlined" value={source.dueDays ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'dueDays' && setFocus(input)}
                                                    inputProps={{ min: 0, max: 255, step: 1 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <TodayIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('discounts')} onChange={() => handleToggleAccordion('discounts')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Zľava ({source.discountCurrent ?? 0}%)</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <TextField fullWidth margin="dense" name="discountLoyalty" type="text" label="Vernostná zľava (%)" variant="outlined" autoComplete="off" value={(source.discountLoyalty ?? 0) > 0 ? source.discountLoyalty : ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'discountLoyalty' && setFocus(input)} disabled
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <PercentIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={3}>
                                                <TextField fullWidth margin="dense" name="discountAdditional" type="text" label="Dodatočná zľava (%)" variant="outlined" autoComplete="off" value={(source.discountAdditional ?? 0) > 0 ? source.discountAdditional : ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'discountAdditional' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Ručne zadaná zľava, ktorá je pripočítaná k vernostnej zľave maximálne do nastavenej výšky maximálnej zľavy.">
                                                                    <PercentIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={3}>
                                                <TextField fullWidth margin="dense" name="discountMaximum" type="text" label="Maximálna zľava (%)" variant="outlined" autoComplete="off" value={(source.discountMaximum ?? 0) > 0 ? source.discountMaximum : ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'discountMaximum' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Maximálna zľava, ktorú môže zákazník dosiahnuť (maximum pre: vernostná + dodatočná zľava). Ak zákazník nemá nastavené vlastné maximum na karte zákazníka, platí nastavenie zo zákazníckej skupiny.">
                                                                    <PercentIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={3}>
                                                <TextField fullWidth margin="dense" name="discountCurrent" type="text" label="Aktuálna zľava (%)" variant="outlined" autoComplete="off" value={(source.discountCurrent ?? 0) > 0 ? source.discountCurrent : ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'discountCurrent' && setFocus(input)} disabled
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <PercentIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} mt={1}>
                                                <Alert severity="info">
                                                    <strong>Aktuálna zľava</strong> predstavuje výslednú zľavu zákazníka, ktorá je aplikovaná na jeho nákup.
                                                    Je automaticky vypočítaná súčtom vernostnej a dodatočnej zľavy, ktorá môže byť maximálne vo výške <strong>maximálnej zľavy</strong> nastavenej pri zákazníkovi alebo zákazníckej skupine.
                                                    <strong>Vernostná zľava</strong> je vypočítaná automaticky podľa pravidiel vernostného systému. <strong>Dodatočnú zľavu</strong> je možné použiť pre nastavenie konkrétej alebo extra zľavy.
                                                </Alert>
                                            </Grid>

                                            <Grid item xs={12} mt={1}>
                                                <Alert severity="warning">
                                                    <strong>Aktuálna zľava</strong> je aplikovaná až na výslednú cenníkovu cenu položiek. To znamená, že na základnú cenu položky sú najskôr aplikované všetky pravidlá pre získanie cenníkovej ceny (napr. zľava pre daný cenník alebo množstevná zľava) a následne na výslednú cenníkovú cenu je ešte aplikovaná aktuálna zľava zákaznika.
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('addresses')} onChange={() => handleToggleAccordion('addresses')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Dodacie adresy ({source.addresses?.length ?? 0})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {source.addresses?.map((address, index) => {
                                                return (
                                                    <Grid item xs={12} mb={1} key={index}>
                                                        <Paper sx={{ p: 2 }}>
                                                            <CustomerCreateAddress index={index} address={address} countries={countries} onChange={handleAddressesChange} />
                                                            <Button variant="contained" size="small" color="secondary" sx={{ mt: 1 }} onClick={() => handleAddressesDelete(index)}>Vymazať</Button>
                                                            {(address.street ?? '').length > 0 && (address.houseNumber ?? '').length > 0 && (address.city ?? '').length > 0 && <Button variant="outlined" size="small" sx={{ mt: 1, ml: 1 }} onClick={() => handleOpenMap((address.street ?? '') + ' ' + (address.houseNumber ?? '') + ' ' + (address.city ?? ''))} startIcon={<LocationOnIcon />}>Mapa</Button>}
                                                            {(address.phone ?? '').length > 5 && <Button variant="outlined" size="small" sx={{ mt: 1, ml: 1 }} onClick={() => handleOpenCall(address.phone ?? '')} startIcon={<PhoneIcon />}>Volať</Button>}
                                                        </Paper>
                                                    </Grid>
                                                )
                                            })}
                                            <Grid item xs={12}>
                                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={handleAddressesAdd}>Pridať adresu</Button>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('banks')} onChange={() => handleToggleAccordion('banks')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Bankové účty ({source.banks?.length ?? 0})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {source.banks?.map((bank, index) => {
                                                return (
                                                    <Grid item xs={12} mb={1} key={index}>
                                                        <Paper sx={{ p: 2 }}>
                                                            <CustomerCreateBank index={index} bank={bank} onChange={handleBankChange} />
                                                            <Button variant="contained" size="small" color="secondary" sx={{ mt: 1 }} onClick={() => handleBankDelete(index)}>Vymazať</Button>
                                                        </Paper>
                                                    </Grid>
                                                )
                                            })}
                                            <Grid item xs={12}>
                                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={handleBankAdd}>Pridať účet</Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {(source.person === true && (
                                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('company')} onChange={() => handleToggleAccordion('company')}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Nadradená firma ({((source.parentCustomerId ?? 0) > 0 ? (source.other?.parentCustomerName ?? '') : 'nie')})</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container columnSpacing={1}>

                                                <Grid item xs={12}>
                                                    <TextField margin="dense" label="Vybraná firma" fullWidth variant="outlined" autoComplete="off" disabled
                                                        value={((source.parentCustomerId ?? 0) > 0 ? (source.other?.parentCustomerName ?? '') : '...')}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <PersonIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>

                                                <Grid item xs={12} mb={1}>
                                                    <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => handleCustomersOpen('company', { person: 2 })}>Vybrať</Button>
                                                    {(source.parentCustomerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => { setSource(prev => ({ ...prev, parentCustomerId: 0 })) }}>Zrušiť prepojenie</Button>}
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField margin="dense" name="department" label="Oddelenie" fullWidth variant="outlined" autoComplete="off" value={source.department ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => (props.autoFocus === 'department') && setFocus(input)}
                                                        inputProps={{ maxLength: 50 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <RoomPreferencesIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField margin="dense" name="classification" label="Pracovná pozícia" fullWidth variant="outlined" autoComplete="off" value={source.classification ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => (props.autoFocus === 'classification') && setFocus(input)}
                                                        inputProps={{ maxLength: 50 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EngineeringIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>

                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}

                                {(source.person === false && (
                                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('contacts')} onChange={() => handleToggleAccordion('contacts')}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Kontaktné osoby ({source.contacts?.length ?? 0})</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {(source.contacts?.length ?? 0) > 0 &&
                                                <Table size="small">
                                                    <TableBody>
                                                        {source.contacts?.map((contact, index) => (
                                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell>
                                                                    <strong>{contact.name}</strong>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {contact.phone}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {contact.email}
                                                                </TableCell>
                                                                <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                                    <IconButton aria-label="Vymazať" size="small" onClick={() => setSource((p) => ({ ...p, contacts: p.contacts?.filter(item => item !== contact) ?? [] }))}>
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            }
                                            <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleCustomersOpen('person', { person: 1 })}>Pridať kontakt</Button>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Interná poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                    inputProps={{ maxLength: 1000 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('password')} onChange={() => handleToggleAccordion('password')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Zmena hesla a doména</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={12} sm={6}>
                                                <TextField margin="dense" error={warning.name === 'password'} name="password" type="password" label="Nové heslo" fullWidth variant="outlined" autoComplete="new-password" value={source.password ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 70 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LockIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField margin="dense" error={warning.name === 'password'} name="passwordverify" type="password" label="Nové heslo znovu" fullWidth variant="outlined" autoComplete="new-password" inputRef={refPasswordVerify}
                                                    inputProps={{ maxLength: 70 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <LockIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="customer-create-domain">Doména *</InputLabel>
                                                    <Select required labelId="customer-create-domain" label="Doména *" name="domainId" value={source.domainId ?? 0} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'domainId' && setFocus(input)}>
                                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} mt={1}>
                                                <Alert severity="info">
                                                    Aktívny partner má povolené prihlásenie len v rámci vybranej domény (bez prístupu do administrácie).
                                                </Alert>
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <CustomFieldValues moduleId={Modules.Customer} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => handleReset(true)} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose} sx={{ mr: 1 }}>Späť</Button>
                        <ButtonGroup disabled={loading} variant="contained" aria-label="Uložiť">
                            <Button disabled={props.disableSaveAction ?? false} type="button" variant="contained" size="small" aria-controls="save-menu" aria-haspopup="true" onClick={(e) => setSaveMenuEl(e.currentTarget)}>
                                <ArrowDropDownIcon />
                            </Button>
                            <Button type="submit" variant="contained">Uložiť</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Box>

                <Menu id="save-menu" anchorEl={saveMenuEl} open={Boolean(saveMenuEl)} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} onClose={() => setSaveMenuEl(null)}>
                    <MenuItem onClick={() => { handleSave(undefined, CustomerCreateSaveAction.SaveAndContinue); setSaveMenuEl(null); }}>
                        <ListItemIcon><SaveIcon fontSize="small" /></ListItemIcon> Uložiť a pokračovať
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleSave(undefined, CustomerCreateSaveAction.SaveAndNew); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový
                    </MenuItem>
                    <MenuItem onClick={() => { handleSave(undefined, CustomerCreateSaveAction.SaveAndNewFromCopy); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový z kópie
                    </MenuItem>
                </Menu>

                <Snackbar open={warning.text.length > 0} autoHideDuration={10000} onClose={() => setWarning(EmptyWarning)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert sx={{ mt: 1 }} variant="filled" severity={warning.color as AlertColor} action={
                        <IconButton color="inherit" size="small" onClick={() => setWarning(EmptyWarning)}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >{warning.text}</Alert>
                </Snackbar>

                {/* Výber z adresára (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <CustomersDialog {...customersDialog} />

            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />
        </>
    )
}

export default CustomerCreate;