
// Otvorenie súboru (url)
export const FileOpen = (url: string, target?: string) => {
    var link = document.createElement("a");
    link.setAttribute("href", url);
    if (target !== undefined) {
        link.setAttribute("target", target);
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// Otvorenie súboru v novom okne (url)
export const FileOpenPopup = (url: string, width?: number, height?: number) => {
    window.open(url, 'newwindow', 'width=' + (width ?? 850) + ',height=' + (height ?? 600));
}

// Funkcia pre stiahnutie súboru (funguje iba v rámci domény kvôli CORS)
export const FileDownload = (url: string, name?: string) => {
    if (name === undefined) {
        name = url.split('/').pop();
    }
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", name ?? '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


// Funkcia pre otvorenie súboru v režime tlače (nefunguje kvôli CORS, nepodarilo sa mi to zatiaľ rozbehať)
export const FilePrint = (url: string) => {
    var x = new XMLHttpRequest()
    x.onerror = (e) => { FileOpen(url, '_blank'); };
    x.onload = (e) => {
        var frame = document.createElement("iframe");
        frame.src = url;
        frame.id = "frame";
        document.body.appendChild(frame);
        setTimeout(() => {
            var frame = document.getElementById('frame');
            // @ts-ignore
            frame.contentWindow.focus();
            // @ts-ignore
            frame.contentWindow.print();
            if (frame) {
                document.body.removeChild(frame);
            }
        }, 100);
    };
    x.open('HEAD', url, true);
    x.send();

    /*
        var w = window.open(url, "PRINT", "height=600,width=800");
        setTimeout(() => {
            w?.print();
            w?.close();
        }, 1500);
    */
};

interface IResizeImageOptions {
    maxSize: number;
    file: File;
}

// Funkcia pre zmenšenie fotiek na strane klienta pred odoslaním na server(zdroj: https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload)
export const FileResizeImage = (settings: IResizeImageOptions) => {
    const { file, maxSize } = settings;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const dataURItoBlob = (dataURI: string) => {
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
            ? atob(dataURI.split(',')[1])
            : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    };

    const resize = () => {
        let width = image.width;
        let height = image.height;

        // Adjust width and height to maintain aspect ratio
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d');
        if (context) {
            // Clear canvas to ensure background is transparent for PNG
            context.clearRect(0, 0, width, height);
            context.drawImage(image, 0, 0, width, height);
        }

        // Choose format based on original file type
        const isTransparentFormat = file.type === 'image/png';
        const dataUrl = canvas.toDataURL(isTransparentFormat ? 'image/png' : 'image/jpeg');

        return dataURItoBlob(dataUrl);
    };

    return new Promise((resolve, reject) => {
        if (!file.type.match(/image.*/)) {
            reject(new Error("Not an image"));
            return;
        }

        // If GIF, return the original file as a Blob without changes
        if (file.type === 'image/gif') {
            resolve(file);
            return;
        }

        // Proceed with resizing for non-GIF formats
        reader.onload = (readerEvent: any) => {
            image.onload = () => resolve(resize());
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};