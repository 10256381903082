import { FormEvent, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';

// Modely
import { StockMovementsFilter, StockMovementType, StockMovementTypes, Warehouse } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select, ListSubheader } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import PersonIcon from '@mui/icons-material/Person';
import NumbersIcon from '@mui/icons-material/Numbers';
import EuroIcon from '@mui/icons-material/Euro';
import { NullMinDate } from '../../utility/Date';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface StockMovementsFilteringProps {
    open: boolean;
    filter?: StockMovementsFilter;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (filter: StockMovementsFilter) => void;
    onClose: () => void;
}

// Prázdny filter
const EmptyFilter: StockMovementsFilter = {
    page: 0,
    pageSize: 25,
    sort: 'date',
    sortAsc: true
}

const StockMovementsFiltering = (props: StockMovementsFilteringProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<StockMovementsFilter>({ ...EmptyFilter });
    const [stockMovementTypes, setStockMovementTypes] = useState<StockMovementType[]>([]);

    // Automaticky naplním zdroj
    useEffect(() => {
        setSource(props.filter ?? EmptyFilter);
    }, [props.filter, props.open]);

    // Načítam zoznam typov
    const loadStockMovementTypes = () => {
        axios
            .get(AppConfig.ApiUri + 'stockmovementtype')
            .then(response => setStockMovementTypes(response.data as StockMovementType[]));
    };
    useEffect(() => loadStockMovementTypes(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            page: 0,
            pageSize: prev.pageSize,
            sort: prev.sort,
            sortAsc: prev.sortAsc
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Filter
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-stockmovementtype">Pohyb</InputLabel>
                                    <Select labelId="filter-stockmovementtype" label="Pohyb" value={source.stockMovementType ?? 0} name="stockMovementType" onChange={(e) => {
                                        handleChange('stockMovementTypeId', 0);
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                        inputRef={input => props.autoFocus === 'stockMovementType' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        <MenuItem key={1} value={StockMovementTypes.Inbound}>Príjem</MenuItem>
                                        <MenuItem key={2} value={StockMovementTypes.Outbound}>Výdaj</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth disabled={(source.stockMovementType ?? 0) === 0}>
                                    <InputLabel id="filter-stockmovementtypeid">Druh pohybu</InputLabel>
                                    <Select labelId="filter-stockmovementtypeid" label="Druh pohybu" value={source.stockMovementTypeId ?? 0} name="stockMovementTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'stockMovementTypeId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        {stockMovementTypes?.filter(item => item.type === source.stockMovementType)?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Dátum od" value={NullMinDate(source.dateFrom)} onChange={(d) => { handleChange('dateFrom', d, 'date') }} inputRef={input => { props.autoFocus === 'dateFrom' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Dátum do" value={NullMinDate(source.dateTo)} onChange={(d) => { handleChange('dateTo', d, 'date') }} inputRef={input => { props.autoFocus === 'dateTo' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Exspirácia (záruka) od" value={NullMinDate(source.expirationDateFrom)} onChange={(d) => { handleChange('expirationDateFrom', d, 'date') }} inputRef={input => { props.autoFocus === 'expirationDateFrom' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Exspirácia (záruka) do" value={NullMinDate(source.expirationDateTo)} onChange={(d) => { handleChange('expirationDateTo', d, 'date') }} inputRef={input => { props.autoFocus === 'expirationDateTo' && setFocus(input) }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>


                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="priceFrom" type="text" label="Cena od" variant="outlined" autoComplete="off" value={source.priceFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="priceTo" type="text" label="Cena do" variant="outlined" autoComplete="off" value={source.priceTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="stockPriceWacFrom" type="text" label="Skladová cena (VNC) od" variant="outlined" autoComplete="off" value={source.stockPriceWacFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'stockPriceWacFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="stockPriceWacTo" type="text" label="Skladová cena (VNC) do" variant="outlined" autoComplete="off" value={source.stockPriceWacTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'stockPriceWacTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>


                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="quantityFrom" type="text" label="Množstvo od" variant="outlined" autoComplete="off" value={source.quantityFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'quantityFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="quantityTo" type="text" label="Množstvo do" variant="outlined" autoComplete="off" value={source.quantityTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'quantityTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="stockQuantityFrom" type="text" label="Stav zásob od" variant="outlined" autoComplete="off" value={source.stockQuantityFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'stockQuantityFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="stockQuantityTo" type="text" label="Stav zásob do" variant="outlined" autoComplete="off" value={source.stockQuantityTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'stockQuantityTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>


                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" name="number" label="Číslo dokladu" fullWidth variant="outlined" autoComplete="off" value={source.number ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'number') && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField margin="dense" name="customer" label="Partner" fullWidth variant="outlined" autoComplete="off" value={source.customer ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'customer') && setFocus(input)}
                                    inputProps={{ maxLength: 35 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Použiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default StockMovementsFiltering;