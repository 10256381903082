import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Product, ProductTranslation, Language, Domain, Category, StockAvailability, PriceList, Warehouse, Parameter, ExternalCatalog, CustomerGroup, Shipment, Tag, ResponseData, Unit, ProductFile, PriceGroup } from '../../models/Models';

// Utility
import { EscapeSlug } from '../../utility/Text';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Box, Snackbar, IconButton, ButtonGroup, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import ProductCreateMain from './ProductCreateMain';
import ProductCreateBasic from './ProductCreateBasic';
import ProductCreatePrice from './ProductCreatePrice';
import ProductCreateDescription from './ProductCreateDescription';
import ProductCreateRelated from './ProductCreateRelated';
import ProductCreateStock from './ProductCreateStock';
import ProductCreateParameter from './ProductCreateParameter';
import ProductCreateFiles from './ProductCreateFiles';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Product => ({
    id: 0,
    actived: true,
    hideCart: false,
    hideReview: false,
    hidePriceUnlogged: false,
    fixedPrice: false,
    fixedAvailability: false,
    domains: [],
    categories: [],
    translations: [],
    volumeDiscounts: [],
    stocks: []
});

// Možnosti pri uložení
export enum ProductCreateSaveAction {
    SaveAndContinue,
    SaveAndNew,
    SaveAndNewFromCopy
}
// Spoločné vstupné parametre pre vnorené komponenty (aby som nemusel pri každom rozpisovať všetko spoločné)
export interface ProductCreateComponentProps {
    source: Product;
    languages: Language[];
    vatRateDefault: number;
    priceIncludeVat: boolean;
    autoFocus?: string;
    setSource: (action: React.SetStateAction<Product>) => void;
    setFocus: (input: any, selectText?: boolean) => void;
    onChange: (property: string, value: any) => void;
    onChangeTranslation: (id: number, property: string, value: any) => void;
    onToggleAccordion: (name: string) => void;
    isAccordionExpanded: (name: string) => boolean;
};

// Vstupné parametre
export interface ProductCreateProps {
    open: boolean;
    id?: number;
    ids?: number[];                     // Hromadná úprava
    categoryId?: number;                // Predvolená kategória
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    categories: Category[];             // Zoznam kategórii (zdroj)
    disableSaveAction?: boolean;        // Vypne rozšírené akcie po uložení
    onSave?: (id?: number, action?: ProductCreateSaveAction) => void;
    onClose: () => void;
    onCategoriesChanged: () => void;    // Ak vnútorne zmením kategórie, tak ich potrebujem vyvolať funkciu pre obnovenie
}

const ProductCreate = (props: ProductCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState<string>('basic');
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [source, setSource] = useState<Product>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [vatRates, setVatRates] = useState<number[]>([]);
    const [vatRateDefault, setVatRateDefault] = useState<number>(0);
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);
    const [priceGroups, setPriceGroups] = useState<PriceGroup[]>([]);
    const [priceIncludeVat, setPriceIncludeVat] = useState<boolean>(false);
    const [stockAvailabilities, setStockAvailabilities] = useState<StockAvailability[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [parameters, setParameters] = useState<Parameter[]>([]);
    const [externalCatalogs, setExternalCatalogs] = useState<ExternalCatalog[]>([]);
    const [externalCatalogsToken, setExternalCatalogsToken] = useState<string>('');
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);
    const [shipments, setShipments] = useState<Shipment[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);
    const [saveMenuEl, setSaveMenuEl] = useState<null | HTMLElement>(null);

    // Sledovanie zmien - aktivované oneskorene, aby sa stihol zmeniť stav "source"
    const [sourceChanged, setSourceChanged] = useState<boolean | undefined>(undefined);
    const setSourceStartWatch = () => { setTimeout(() => { setSourceChanged(false); }, 250); };
    const setSourceStopWatch = () => { setSourceChanged(undefined); };
    useEffect(() => {
        if (sourceChanged === false) {
            setSourceChanged(true);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Reset formuláru
    const handleReset = (keepId?: boolean) => {
        setSource(prev => {
            // Nájdem predvolené aktívne hodnoty aktívnych parametrov
            var defaultParameters: number[] = [];
            parameters.filter(parameter => parameter.actived === true).forEach(parameter => {
                var parameterItems = (parameter.items?.filter(item => item.actived === true && item.default === true)?.map(item => item.id ?? 0) ?? []);
                if (parameterItems.length > 0) {
                    // Multivýber viacerých hodnôt, je povolený pre parameter typu: (0) vlastnosti, (3) konfigurátor - multivýber, pre všetko ostatné bude použitá iba jedna (prvá) hodnota
                    if (parameter.type === 0 || parameter.type === 3) {
                        defaultParameters = [...defaultParameters ?? [], ...(parameter.items?.filter(item => item.actived === true && item.default === true)?.map(item => item.id ?? 0) ?? [])];
                    }
                    else {
                        defaultParameters = [...defaultParameters ?? [], parameterItems[0]];
                    }
                }
            });
            return ({
                ...EmptySource(),
                id: (keepId ?? false) === true ? prev.id : 0,
                categoryId: (props.categoryId ?? 0) > 0 ? props.categoryId : 0,
                unitId: units?.find(unit => unit.other?.translationName === 'ks')?.id ?? 0,
                priceVatRate: vatRateDefault,
                pricePurchaseVatRate: vatRateDefault,
                parameters: defaultParameters
            })
        });
        setSourceStartWatch();
    };
    const handleResetConfirm = (keepId?: boolean) => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Reset', children: 'Skutočne chcete zrušiť všetky vykonané zmeny?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                loadData();
            }
        }));
    }

    // Aby sa v textových políčkach na vybraných miestach nezobrazovali nuly, tak je potrebné ich nahradiť za undefined
    const discardEmptyNumbers = (entity: Product): Product => {
        const checkNumber = (property?: number): number | undefined => {
            return (property ?? 0) !== 0 ? property : undefined;
        }
        if (entity.priceLists !== undefined && entity.priceLists?.length > 0) {
            entity.priceLists = [...entity.priceLists.map(priceList => ({
                ...priceList,
                price: checkNumber(priceList.price),
                discount: checkNumber(priceList.discount),
                cartMinimalQuantity: checkNumber(priceList.cartMinimalQuantity),
                cartMaximalQuantity: checkNumber(priceList.cartMaximalQuantity),
            }))]
        }
        if (entity.stocks !== undefined && entity.stocks?.length > 0) {
            entity.stocks = [...entity.stocks.map(stock => ({
                ...stock,
                quantity: checkNumber(stock.quantity),
                priceWac: checkNumber(stock.priceWac),
                pricePurchaseLast: checkNumber(stock.pricePurchaseLast),
                reservedQuantity: checkNumber(stock.reservedQuantity),
                orderedQuantity: checkNumber(stock.orderedQuantity),
                minimalQuantity: checkNumber(stock.minimalQuantity),
                maximalQuantity: checkNumber(stock.maximalQuantity),
            }))]
        }
        entity.infoUnitQuantity = checkNumber(entity.infoUnitQuantity);
        entity.stockQuantity = checkNumber(entity.stockQuantity);
        entity.stockReservedQuantity = checkNumber(entity.stockReservedQuantity);
        entity.stockOrderedQuantity = checkNumber(entity.stockOrderedQuantity);
        entity.stockMinimalQuantity = checkNumber(entity.stockMinimalQuantity);
        entity.stockMaximalQuantity = checkNumber(entity.stockMaximalQuantity);
        entity.price = checkNumber(entity.price);
        entity.priceDiscount = checkNumber(entity.priceDiscount);
        entity.pricePurchase = checkNumber(entity.pricePurchase);
        entity.priceRecommended = checkNumber(entity.priceRecommended);
        entity.cartMinimalQuantity = checkNumber(entity.cartMinimalQuantity);
        entity.cartMaximalQuantity = checkNumber(entity.cartMaximalQuantity);
        entity.weight = checkNumber(entity.weight);
        entity.dimensionWidth = checkNumber(entity.dimensionWidth);
        entity.dimensionLength = checkNumber(entity.dimensionLength);
        entity.dimensionHeight = checkNumber(entity.dimensionHeight);
        entity.ordinalNumber = checkNumber(entity.ordinalNumber);
        return entity;
    }

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            if (tabValue !== 'basic') {
                setTabValue('basic');
            }
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded(['images', 'descfull']);
            setSourceStopWatch();
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();
        if ((props.id ?? 0) === 0) {
            return;
        }
        // Ak som v zozname klikol na množstvo na sklade, tak pri otvorení zobrazím záložku zásoby
        if (props.autoFocus === 'stockQuantity') {
            setTabValue('stock');
        }
        // Ak som v zozname klikol na niektorú z cien, tak pri otvorení zobrazím záložku cenník
        if (props.autoFocus === 'price' ||
            props.autoFocus === 'priceDiscount' ||
            props.autoFocus === 'pricePurchase') {
            setTabValue('price');
        }
        // Ak som v zozname klikol na niektorý z kódov, tak pri otvorení zobrazím rozbalím harmoniku kódy
        if (props.autoFocus === 'ean' ||
            props.autoFocus === 'codeSupplier' ||
            props.autoFocus === 'mpn' ||
            props.autoFocus === 'model') {
            setAccordionExpanded(prev => [...prev, 'codes']);
        }
        // Zobrazenie záložky parametre
        if (props.autoFocus === 'parameters') {
            setTabValue('parameter');
        }
        // Zobrazenie záložky súbory
        if (props.autoFocus === 'file') {
            setTabValue('files');
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'product/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    var data = response.data as Product;

                    // Kópia
                    if (props.copy) {
                        data.id = 0;
                        data.code += 'C';
                        // Vynulujem primárne kľúče relačných záznamov (inak by sa preniesli zo záznamu na záznam)
                        var filesCount = (data.files?.length ?? 0);
                        if (filesCount > 0) {
                            const files = data.files as ProductFile[];
                            data.files = [...files.map(file => ({ ...file, id: 0 }))];
                        }
                        data.stocks = [];
                        data.stockQuantity = undefined;
                        data.stockReservedQuantity = undefined;
                        data.stockOrderedQuantity = undefined;
                    }

                    // Aby sa v textových políčkach na vybraných miestach nezobrazovali nuly, tak je potrebné ich nahradiť za undefined
                    data = discardEmptyNumbers(data);

                    setSource(data);
                    setSourceStartWatch();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Funkcia pre naplnenie stavu číselníkov
    const applyFormData = (data: any) => {
        if (data.language) {
            setLanguages(data.language);
        }
        if (data.domain) {
            setDomains(data.domain);
        }
        if (data.vatrate) {
            setVatRates(data.vatrate);
        }
        if (data.vatratesettings) {
            setVatRateDefault(data.vatratesettings.defaultVatRate ?? 0);
        }
        if (data.pricelistsettings) {
            setPriceIncludeVat(data.pricelistsettings.priceIncludeVat ?? false);
        }
        if (data.pricelist) {
            setPriceLists(data.pricelist);
        }
        if (data.pricegroup) {
            setPriceGroups(data.pricegroup);
        }
        if (data.stockavailability) {
            setStockAvailabilities(data.stockavailability);
        }
        if (data.warehouse) {
            setWarehouses(data.warehouse);
        }
        if (data.parameter) {
            setParameters(data.parameter);
        }
        if (data.externalcatalog) {
            setExternalCatalogs(data.externalcatalog);
        }
        if (data.externalcatalogtoken) {
            setExternalCatalogsToken(data.externalcatalogtoken);
        }
        if (data.customergroup) {
            setCustomerGroups(data.customergroup);
        }
        if (data.shipment) {
            setShipments(data.shipment);
        }
        if (data.tag) {
            setTags(data.tag);
        }
        if (data.unit) {
            setUnits(data.unit);
        }
    };

    // Funkcie pre uloženie a načítanie dát z localstorage (zatiaĺ vypnuté, treba zabezpečiť obnovenie dát a podobne)
    // try { const formdata = localStorage.getItem('formdata-product'); if (formdata !== null) { applyFormData(JSON.parse(formdata)); } } catch (error) { }
    // try { localStorage.setItem('formdata-product', JSON.stringify(response.data)); } catch (e) { }

    // Načítam dáta pre formulár jedným volaním
    const loadFormData = () => {
        axios
            .get(AppConfig.ApiUri + 'product/formdata')
            .then(response => {
                if (response.data) {
                    // Aplikujem
                    applyFormData(response.data);
                }
            });
    };
    useEffect(() => loadFormData(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam zoznam parametrov (volá vnorený komponent a iba vtedy ak sa parametre zmenili)
    const loadParameters = () => {
        axios
            .get(AppConfig.ApiUri + 'parameter', {
                params: {
                    withItems: true
                }
            })
            .then(response => {
                setParameters(response.data);
            });
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: ProductTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            if (property === 'name') {
                item['url'] = EscapeSlug(value);
                item['seoTitle'] = value;
            }
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event?: FormEvent<HTMLFormElement>, action?: ProductCreateSaveAction) => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }

        // Skontrolujem kód a ostatné povinné údaje
        if ((source.code ?? '') === '') {
            setWarning({ ...EmptyWarning, text: 'Vyplňte kód produktu!' });
            return;
        }

        // Každý jazyk musí mať záznam a vyplnený názov
        if ((source.translations?.length ?? 0) < languages.length || source.translations?.find(item => (item.name ?? '') === '') !== undefined) {
            setWarning({ ...EmptyWarning, text: 'Vyplňte názov produktu!' });
            return;
        }

        // URL pre každý jazyk
        if (source.translations?.find(item => (item.url ?? '') === '') !== undefined) {
            setWarning({ ...EmptyWarning, text: 'Vyplňte URL produktu!' });
            return;
        }

        // Vráti vybrané id položiek, ktoré patria pod daný parameter
        const getParameterValues = (parameterId: number): number[] => {
            return (parameters.find(item => item.id === parameterId)?.items?.filter(item => source.parameters?.includes(item.id ?? 0))?.map(item => item.id ?? 0) ?? [])?.filter(item => item > 0) ?? [];
        };

        // Zoznam povinných parameterov
        const requiredParameters: number[] = parameters?.filter(item => item.required)?.map(item => item.id ?? 0) ?? [];

        // Skontrolujem všetky povinné parametre
        if (requiredParameters?.find(item => (getParameterValues(item)?.length ?? 0) === 0) !== undefined) {
            setWarning({ ...EmptyWarning, text: 'Vyplňte všetky povinné parametre produktu!' });
            return;
        }

        // Údaje pre uloženie
        let sourceToSave = { ...source };

        // Vložím id produktov pre hromadnú úpravu
        if (props.ids !== undefined) {
            sourceToSave = { ...sourceToSave, other: { ...sourceToSave.other, batchIds: props.ids } };
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'product', sourceToSave)
            .then(response => {
                if (response.data !== undefined) {
                    const responseData = response.data as ResponseData
                    if (!responseData.success) {
                        switch (responseData.data) {
                            case 'code': {
                                setWarning({ ...EmptyWarning, text: 'Zadaný kód produktu "' + (source.code ?? '') + '" už používa iný produkt. Kód produktu musí byť unikátny v rámci celého katalógu.' });
                                break;
                            }
                            case 'plu': {
                                setWarning({ ...EmptyWarning, text: 'Zadaný PLU produktu "' + (source.plu ?? '') + '" už používa iný produkt. PLU produktu musí byť unikátny v rámci celého katalógu.' });
                                break;
                            }
                            default: {
                                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
                                break;
                            }
                        }
                        return;
                    }
                    props.onClose();
                    if (props.onSave) {
                        props.onSave((responseData.data ?? 0) as number, action);
                    }
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any, selectText?: boolean) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => {
                input?.focus();
                if (selectText === true) {
                    setTimeout(() => { input?.select(); }, 250);
                }
            }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Funkcia pre vygenerovanie spoločných vstupných parametrov (aby som nemusel pri každom rozpisovať všetko spoločné)
    const componentProps = (): ProductCreateComponentProps => {
        return {
            source: source,
            languages: languages,
            autoFocus: props.autoFocus,
            priceIncludeVat: priceIncludeVat,
            vatRateDefault: vatRateDefault,
            setSource: setSource,
            setFocus: setFocus,
            onChange: handleChange,
            onChangeTranslation: handleChangeTranslation,
            onToggleAccordion: handleToggleAccordion,
            isAccordionExpanded: isAccordionExpanded
        }
    };

    // Zobrazenie názvu produktu v nadpise
    const dialogTitle = (): string => {
        let title = (source.translations?.find(item => item.languageId === (languages[0]?.id ?? 0))?.name ?? '');
        if (title === '') {
            title = ((props?.id ?? 0) > 0 ? 'Produkt' : 'Nový produkt');
        }
        return (title.length > 40 ? title.substring(0, 40) + '...' : title);
    };

    // Automatický prepočet množstva na sklade (vlastné sklady / externé sklady)
    useEffect(() => {
        let stockQuantity = source.stocks?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0);
        setSource(prev => ({
            ...prev,
            stockQuantity: stockQuantity
        }));
    }, [warehouses, source.stocks]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="lg" fullWidth open={props.open} scroll="body" onClose={(e, r) => {
                if (r !== 'backdropClick') {
                    if (sourceChanged !== true) {
                        props.onClose();
                        return;
                    }
                    setConfirm(prev => ({
                        ...prev, open: true, title: 'Neuložené zmeny', children: 'Skutočne chcete zavrieť okno bez uloženia zmien?', onConfirm: () => {
                            setConfirm(prev => ({ ...prev, open: false }));
                            props.onClose();
                        }
                    }));
                }
            }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave} noValidate>
                    <DialogTitle sx={{ px: 3, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {dialogTitle()}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>

                        {(props.ids !== undefined &&
                            <Alert severity="warning" sx={{ mb: 2 }} style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
                                <strong>Hromadná úprava ({props.ids.length}x) - </strong> Pozor! Vykonané zmeny budú aplikované na všetky vybrané produkty.
                            </Alert>
                        )}

                        <ProductCreateMain {...componentProps()} domains={domains} categories={props.categories} vatRates={vatRates} stockAvailabilities={stockAvailabilities} customerGroups={customerGroups} shipments={shipments} tags={tags} units={units} imageClick={() => setTabValue('files')} stockClick={() => setTabValue('stock')} onCategoriesChanged={props.onCategoriesChanged} />

                        <Grid container columnSpacing={1} sx={{ mt: 0 }}>
                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        <Tab value="basic" label="Základné" />
                                        <Tab value="parameter" label="Parametre" />
                                        <Tab value="files" label={'Súbory (' + (source.files?.length ?? 0) + ')'} />
                                        <Tab value="price" label="Cenník" />
                                        <Tab value="stock" label="Zásoby" />
                                        <Tab value="description" label="Popis" />
                                        <Tab value="relation" label={'Súvisiace (' + (source.relatedAny?.length ?? 0) + '/' + (source.relatedAlternative?.length ?? 0) + '/' + (source.relatedAccessory?.length ?? 0) + '/' + (source.relatedSpareParts?.length ?? 0) + ')'} />
                                    </Tabs>
                                </Box>
                                <TabContext value={tabValue}>
                                    <TabPanel value="basic" sx={{ px: '0' }}>
                                        <ProductCreateBasic {...componentProps()} domains={domains} categories={props.categories} vatRates={vatRates} stockAvailabilities={stockAvailabilities} customerGroups={customerGroups} shipments={shipments} tags={tags} units={units} externalCatalogs={externalCatalogs} externalCatalogsToken={externalCatalogsToken} onCategoriesChanged={props.onCategoriesChanged} />
                                    </TabPanel>
                                    <TabPanel value="parameter" sx={{ px: '0' }}>
                                        <ProductCreateParameter {...componentProps()} parameters={parameters} onParameterItemsChanged={loadParameters} />
                                    </TabPanel>
                                    <TabPanel value="files" sx={{ px: '0' }}>
                                        <ProductCreateFiles {...componentProps()} />
                                    </TabPanel>
                                    <TabPanel value="price" sx={{ px: '0' }}>
                                        <ProductCreatePrice {...componentProps()} priceLists={priceLists} priceGroups={priceGroups} vatRates={vatRates} units={units} warehouses={warehouses} />
                                    </TabPanel>
                                    <TabPanel value="stock" sx={{ px: '0' }}>
                                        <ProductCreateStock {...componentProps()} stockAvailabilities={stockAvailabilities} warehouses={warehouses} />
                                    </TabPanel>
                                    <TabPanel value="description" sx={{ px: '0' }}>
                                        <ProductCreateDescription {...componentProps()} />
                                    </TabPanel>
                                    <TabPanel value="relation" sx={{ px: '0' }}>
                                        <ProductCreateRelated {...componentProps()} />
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => handleResetConfirm()} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose} sx={{ mr: 1 }}>Späť</Button>
                        <ButtonGroup disabled={loading} variant="contained" aria-label="Uložiť">
                            <Button disabled={props.disableSaveAction ?? false} type="button" variant="contained" size="small" aria-controls="save-menu" aria-haspopup="true" onClick={(e) => setSaveMenuEl(e.currentTarget)}>
                                <ArrowDropDownIcon />
                            </Button>
                            <Button type="submit" variant="contained">Uložiť</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Box>

                <Menu id="save-menu" anchorEl={saveMenuEl} open={Boolean(saveMenuEl)} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} onClose={() => setSaveMenuEl(null)}>
                    <MenuItem onClick={() => { handleSave(undefined, ProductCreateSaveAction.SaveAndContinue); setSaveMenuEl(null); }}>
                        <ListItemIcon><SaveIcon fontSize="small" /></ListItemIcon> Uložiť a pokračovať
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleSave(undefined, ProductCreateSaveAction.SaveAndNew); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový
                    </MenuItem>
                    <MenuItem onClick={() => { handleSave(undefined, ProductCreateSaveAction.SaveAndNewFromCopy); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový z kópie
                    </MenuItem>
                </Menu>

                <Snackbar open={warning.text.length > 0} autoHideDuration={10000} onClose={() => setWarning(EmptyWarning)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert sx={{ mt: 1 }} variant="filled" severity={warning.color as AlertColor} action={
                        <IconButton color="inherit" size="small" onClick={() => setWarning(EmptyWarning)}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >{warning.text}</Alert>
                </Snackbar>

            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

        </>
    )
}

export default ProductCreate;