import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimalOnKeyDown, ConvertToInt } from '../../../utility/Number';

// Modely
import { NumberSequence, NumberSequencePeriods, Module, Department, Warehouse, Modules, Branch } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, Tooltip, Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import NumbersIcon from '@mui/icons-material/Numbers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): NumberSequence => ({
    id: 0,
    template: 'RRCCCCC',
    module: Modules.None,
    moduleTypeId: 0,
    period: NumberSequencePeriods.Annual,
    warehouseId: 0,
    branchId: 0,
    departmentId: 0
});

// Vstupné parametre
export interface NumberSequenceCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const NumberSequenceCreate = (props: NumberSequenceCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [modules, setModules] = useState<Module[]>([]);
    const [branches, setBranches] = useState<Branch[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [source, setSource] = useState<NumberSequence>(EmptySource());

    // Číselníky
    const loadBranches = () => {
        axios
            .get(AppConfig.ApiUri + 'branch')
            .then(response => setBranches((response.data as Branch[]).filter(item => item.actived)));
    };
    const loadDepartments = () => {
        axios
            .get(AppConfig.ApiUri + 'department')
            .then(response => setDepartments((response.data as Department[]).filter(item => item.actived)));
    };
    const loadWarehouses = () => {
        axios
            .get(AppConfig.ApiUri + 'warehouse')
            .then(response => setWarehouses(response.data as Warehouse[]));
    };
    useEffect(() => {
        loadBranches();
        loadDepartments();
        loadWarehouses();
    }, []);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'numbersequence/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    var data = response.data as NumberSequence;
                    if (props.copy) {
                        data.id = 0;
                        data.increments = [];
                    }
                    setSource(data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam typov
    const loadModules = () => {
        axios
            .get(AppConfig.ApiUri + 'module', { params: { withItems: true } })
            .then(response => setModules((response.data as Module[])?.filter(module => module.numberSequence === true)));
    };
    useEffect(() => loadModules(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeIncrements = (index: number, property: string, value: any) => {
        setSource((p) => {
            const changed = p.increments ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...p, increments: changed };
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if ((source.module ?? Modules.None) === Modules.None) {
            setWarning({ ...EmptyWarning, name: 'moduleId', text: 'Musíte vybrať agendu!' });
            return;
        }

        if (source.moduleTypeId === 0 && moduleTypeRequired === true) {
            setWarning({ ...EmptyWarning, name: 'moduleTypeId', text: 'Musíte vybrať druh agendy!' });
            return;
        }
        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'numbersequence', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Ak existujú druhy agendy, tak sú povinné
    const moduleTypeRequired = (modules?.find(module => module.id === (source.module ?? 0))?.types?.length ?? 0) > 0;

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Číslovanie dokladu' : 'Nové číslovanie dokladu')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="numbersequence-create-module">Agenda *</InputLabel>
                                    <Select required labelId="numbersequence-create-module" label="Agenda *" value={source.module ?? 0} name="module" onChange={(e) => {
                                        handleChange('moduleTypeId', 0);
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                        inputRef={input => (props.autoFocus === 'module' || props.autoFocus === 'moduleName') && setFocus(input)}>
                                        <MenuItem value={0}>-</MenuItem>
                                        {modules?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="numbersequence-create-moduletype">Druh agendy {moduleTypeRequired ? '*' : ''}</InputLabel>
                                    <Select required={moduleTypeRequired} labelId="numbersequence-create-moduletype" label={'Druh agendy ' + (moduleTypeRequired ? '*' : '')} value={source.moduleTypeId ?? 0} name="moduleTypeId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => (props.autoFocus === 'moduleTypeId' || props.autoFocus === 'moduleTypeName') && setFocus(input)}>
                                        <MenuItem value={0}>-</MenuItem>
                                        {modules?.find(module => module.id === (source.module ?? 0))?.types?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="numbersequence-create-branch">Prevádzka (pobočka)</InputLabel>
                                    <Select labelId="numbersequence-create-branch" label="Prevádzka (pobočka)" value={source.branchId ?? 0} name="branchId" onChange={(e) => {
                                        handleChange('departmentId', 0);
                                        handleChange('warehouseId', 0);
                                        handleChange(e.target.name, e.target.value);
                                    }}
                                        inputRef={input => { (props.autoFocus === 'branchId' || props.autoFocus === 'branchName') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {branches?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl disabled={(source.branchId ?? 0) === 0} margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="numbersequence-create-department">Stredisko (oddelenie)</InputLabel>
                                    <Select labelId="numbersequence-create-department" label="Stredisko (oddelenie)" value={source.departmentId ?? 0} name="departmentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'departmentId' || props.autoFocus === 'departmentName') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {departments?.filter(item => item.branchId === source.branchId)?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="numbersequence-create-warehouse">Sklad</InputLabel>
                                    <Select labelId="numbersequence-create-warehouse" label="Sklad" value={source.warehouseId ?? 0} name="warehouseId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { (props.autoFocus === 'warehouseId' || props.autoFocus === 'warehouseName') && setFocus(input) }}>
                                        <MenuItem key={0} value={0}>-</MenuItem>
                                        {warehouses?.filter(item => (source.branchId ?? 0) === 0 || item.branchId === source.branchId)?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="template" label="Šablóna (formát čísla)" fullWidth variant="outlined" autoComplete="off" value={source.template ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'template' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 32 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Šablóna, podľa ktorej sa číslo naformátuje. V šablóne je možné použiť nasledovné značky: CCCC, RR/RRRR, M/MM/MMM, D/DD/DDD, UU/UUU.">
                                                    <NumbersIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl required margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="numbersequence-create-period">Obdobie</InputLabel>
                                    <Select labelId="numbersequence-create-period" label="Obdobie" value={source.period ?? 0} name="period" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => { props.autoFocus === 'period' && setFocus(input) }}>
                                        <MenuItem value={NumberSequencePeriods.Permanent}>Trvalé</MenuItem>
                                        <MenuItem value={NumberSequencePeriods.Annual}>Ročné</MenuItem>
                                        <MenuItem value={NumberSequencePeriods.Monthly}>Mesačné</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info">
                                    <strong>C</strong> - poradové číslo, napr.: 001 (CCC)<br />
                                    <strong>RR/RRRR</strong> - rok: 25/2025<br />
                                    <strong>M/MM/MMM</strong> - mesiac: 9/09/SEP<br />
                                    <strong>D/DD/DDD</strong> - deň: 1/01/PON<br />
                                    <strong>(ABC)</strong> - vlastný text (vložiť do zátvoriek)
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6">Posledné použité číslo podľa obdobia</Typography>
                                <Typography textAlign="center" variant="body2" gutterBottom>Neexistujúce číslo bude pre príslušné obdobie vytvorené automaticky</Typography>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: '30%' }}>Rok</TableCell>
                                            <TableCell sx={{ width: '30%' }}>Mesiac</TableCell>
                                            <TableCell>Číslo</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {source.increments?.map((increment, index) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                    <TextField required fullWidth size="small" name="year" type="text" variant="outlined" autoComplete="off" value={increment.year ?? ''}
                                                        onChange={(e) => { handleChangeIncrements(index, e.target.name, ConvertToInt(e.target.value)) }}
                                                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EditIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </TableCell>
                                                <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                    <Select disabled={source.period !== NumberSequencePeriods.Monthly} fullWidth size="small" labelId="numbersequence-create-month" value={increment.month ?? 0} name="month" onChange={(e) => { handleChangeIncrements(index, e.target.name, ConvertToInt(e.target.value)) }}>
                                                        <MenuItem value={0}>-</MenuItem>
                                                        <MenuItem value={1}>Január</MenuItem>
                                                        <MenuItem value={2}>Február</MenuItem>
                                                        <MenuItem value={3}>Marec</MenuItem>
                                                        <MenuItem value={4}>Apríl</MenuItem>
                                                        <MenuItem value={5}>Máj</MenuItem>
                                                        <MenuItem value={6}>Jún</MenuItem>
                                                        <MenuItem value={7}>Júl</MenuItem>
                                                        <MenuItem value={8}>August</MenuItem>
                                                        <MenuItem value={9}>September</MenuItem>
                                                        <MenuItem value={10}>Október</MenuItem>
                                                        <MenuItem value={11}>November</MenuItem>
                                                        <MenuItem value={12}>December</MenuItem>
                                                    </Select>
                                                </TableCell>
                                                <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                    <TextField fullWidth size="small" name="number" type="text" variant="outlined" autoComplete="off" value={increment.number ?? ''}
                                                        onChange={(e) => { handleChangeIncrements(index, e.target.name, ConvertToInt(e.target.value)) }}
                                                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EditIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </TableCell>
                                                <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                    <IconButton aria-label="Vymazať" size="small" onClick={() => setSource((p) => ({ ...p, increments: p.increments?.filter(item => item !== increment) ?? [] }))}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => {
                                    var year = ((source.increments?.length ?? 0) > 0 ? (Math.max(...(source.increments?.map(i => i.year ?? 0) ?? [])) ?? 0) + 1 : 0);
                                    if (year === 0 && source.period !== NumberSequencePeriods.Permanent) {
                                        year = new Date().getFullYear();
                                    }
                                    setSource((p) => ({ ...p, increments: [...p.increments ?? [], { year: year }] }));
                                }}>Pridať záznam</Button>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Alert severity="info">
                                    <strong>Automatické dosadenie číselného radu</strong> je aplikované podľa zhody nastavení v poradí:<br />
                                    - Sklad a oddelenie<br />
                                    - Sklad a prevádzka, oddelenie nenastavené<br />
                                    - Iba sklad, oddelenie a prevádzka nenastavené<br />
                                    - Iba oddelenie, sklad nenastavený<br />
                                    - Iba prevádzka, oddelenie a sklad nenastavené
                                </Alert>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default NumberSequenceCreate;