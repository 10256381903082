import { useState } from 'react';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Documents from './Documents';

// Modely
import { DocumentLite, DocumentsFilter, DocumentType } from '../../models/Models';

// Vstupné parametre
export interface DocumentsDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    types?: DocumentType[]; // Druhy dokladu - číselník
    type?: number; // Druh dokladu - id
    filter?: DocumentsFilter;
    onSelect?: (documents: DocumentLite[], argument?: any) => void;
    onClose?: () => void;
}

const DocumentsDialog = (props: DocumentsDialogProps) => {

    // Označené produkty
    const [selected, setSelected] = useState<DocumentLite[]>([]);
    // const [documentType, setDocumentType] = useState<DocumentType>({ id: props?.type ?? 0});

    // Automaticky nájdem aktuálny typ dokladu po zmene id druhu dokladu, alebo načítania číselníku
    // useEffect(() => {
    //     if((props?.type ?? 0) === 0 || (props.types?.length ?? 0) === 0) {
    //         return;
    //     }
    //     setDocumentType(props.types?.find(item => item.id === props?.type) ?? { });
    // }, [props?.type, props?.types]);

    // Zmena výberu s možnosťou potvrdenia výberu
    const handleSelectionChanged = (items: DocumentLite[], confirm: boolean) => {
        if (confirm) {
            handleConfirm(items); // Pri potvrdení rovno odovzdám aktuálny zoznam, nemôžem čakať na zmenu stavu
        } else {
            setSelected(items); // Zmením aktuálny výber
        }
    };

    // Potvrdenie výberu (nepoužívam zoznam zo stavu keďže zmnena stavu sa môže dokončiť neskôr ako príde potvrdenie)
    const handleConfirm = (items: DocumentLite[]) => {
        if (props?.onSelect) {
            props?.onSelect(items, props.argument);
        }
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                {/* <DialogTitle>{(documentType?.id ?? 0) > 0 ? documentType.other?.translationName : 'Doklady'}</DialogTitle> */}
                <DialogContent sx={{ height: '87vh' }}>
                    <Documents onSelectionChanged={handleSelectionChanged} types={props.types} type={props.type} filter={props.filter} filterDisableAutosave={true} />
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={props.onClose}>Späť</Button>
                    <Button onClick={() => handleConfirm(selected)} type="submit" variant="contained">Potvrdiť výber</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DocumentsDialog;
