// Komponenty
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import Products from './Products';

// Modely
import { ProductLite, ProductsFilter } from '../../models/Models';
import { useState } from 'react';

// Vstupné parametre
export interface ProductsDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    filter?: ProductsFilter;
    onSelect?: (products: ProductLite[], argument?: any) => void;
    onClose?: () => void;
}

const ProductsDialog = (props: ProductsDialogProps) => {

    // Označené produkty
    const [selected, setSelected] = useState<ProductLite[]>([]);

    // Zmena výberu s možnosťou potvrdenia výberu
    const handleSelectionChanged = (items: ProductLite[], confirm: boolean) => {
        if (confirm) {
            handleConfirm(items); // Pri potvrdení rovno odovzdám aktuálny zoznam, nemôžem čakať na zmenu stavu
        } else {
            setSelected(items); // Zmením aktuálny výber
        }
    };

    // Potvrdenie výberu (nepoužívam zoznam zo stavu keďže zmnena stavu sa môže dokončiť neskôr ako príde potvrdenie)
    const handleConfirm = (items: ProductLite[]) => {
        if (props?.onSelect) {
            props?.onSelect(items, props.argument);
        }
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                {/* <DialogTitle>Produkty</DialogTitle> */}
                <DialogContent sx={{ height: '87vh' }}>
                    <Products onSelectionChanged={handleSelectionChanged} filter={props.filter} filterDisableAutosave={true} />
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={props.onClose}>Späť</Button>
                    <Button onClick={() => handleConfirm(selected)} type="submit" variant="contained">Potvrdiť výber</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ProductsDialog;