import React, { useState } from 'react';

// Modely
import { Document } from '../../models/Models';

// Komponenty
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Iframe from '../../components/Iframe';
import TinyMCEDialog, { TinyMCEDialogProps } from '../tinymce/TinyMCEDialog';
import { TinyMCEStyleClient } from '../tinymce/TinyMCEStyleClient';

// Ikony

// Predpis pre argumenty TinyMCE
interface TinyMCEArgument {
    property: string;
}

// Vstupné parametre, vychádzam zo spoločného predpisu
interface DocumentCreateDescriptionProps {
    source: Document;
    setSource: (action: React.SetStateAction<Document>) => void;
};

const DocumentCreateDescription = (props: DocumentCreateDescriptionProps) => {

    // Stav
    const [tinyMCEDialog, setTinyMCEDialog] = useState<TinyMCEDialogProps>({
        open: false,
        content: '',
        contentStyle: 'body { font-family: Arial, sans-serif; }', // CSS štýl, ktorý sa pridáva do vygenerovaného HTML ako predvolený štýl
        // contentTagsIds: [],
        onSave: (content: string, argument: TinyMCEArgument) => {
            handleChange(argument.property, content);
            handleTinymceClose();
        },
        onClose: () => handleTinymceClose()
    });

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        props.setSource(prev => ({ ...prev, [property]: value }));
    }

    // Zobrazenie HTML editoru
    const handleTinymceOpen = (argument: TinyMCEArgument, title: string, content: string) => {
        setTinyMCEDialog(prev => ({ ...prev, open: true, title: title, argument: argument, content: content }));
    };
    const handleTinymceClose = () => {
        setTinyMCEDialog(prev => ({ ...prev, open: false }));
    };

    return (
        <>
            <Grid container columnSpacing={1}>


                <Grid item xs={12} mt={1} mb={1}>
                    <Typography variant='h5'>Podrobný popis</Typography>
                </Grid>
                <Grid item xs={12} sx={{ height: '130px' }}>
                    <Iframe title={'Podrobný popis'} content={(props.source.description ?? '') + '<style>' + (tinyMCEDialog.contentStyle ?? '') + TinyMCEStyleClient + '</style>'} />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => { handleTinymceOpen({ property: 'description' }, 'Podrobný popis', props.source.description ?? ''); }}>Upraviť</Button>
                </Grid>


                <Grid item xs={12} mt={2} mb={1}>
                    <Typography variant='h5'>Text pred položkami</Typography>
                </Grid>
                <Grid item xs={12} sx={{ height: '130px' }}>
                    <Iframe title={'Text pred položkami'} content={(props.source.textBefore ?? '') + '<style>' + (tinyMCEDialog.contentStyle ?? '') + TinyMCEStyleClient + '</style>'} />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => { handleTinymceOpen({ property: 'textBefore' }, 'Text pred položkami', props.source.textBefore ?? ''); }}>Upraviť</Button>
                </Grid>


                <Grid item xs={12} mt={2} mb={1}>
                    <Typography variant='h5'>Text za položkami</Typography>
                </Grid>
                <Grid item xs={12} sx={{ height: '130px' }}>
                    <Iframe title={'Text za položkami'} content={(props.source.textAfter ?? '') + '<style>' + (tinyMCEDialog.contentStyle ?? '') + TinyMCEStyleClient + '</style>'} />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => { handleTinymceOpen({ property: 'textAfter' }, 'Text za položkami', props.source.textAfter ?? ''); }}>Upraviť</Button>
                </Grid>


            </Grid>
            <TinyMCEDialog {...tinyMCEDialog} />
        </>
    )
};

export default DocumentCreateDescription;
