import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { Watermark as WatermarkType } from '../../../models/Models';
import { File } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, AlertTitle, Button, Card, CardActions, CardMedia, FormControlLabel, Grid, LinearProgress, Paper, Radio, Slider, Switch, Typography } from '@mui/material';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import { Content, ContentPaperScroll } from '../../../layout/Content';

// Ikony
import DeleteIcon from '@mui/icons-material/Delete';

// Predpis pre generovanie miniatúr
interface FilePreview {
    total: number;
    processing: boolean;
}

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): WatermarkType => ({
    full: {
        actived: false,
        fileId: 0,
        position: 4,
        size: 20,
        opacity: 100
    },
    medium: {
        actived: false,
        fileId: 0,
        position: 4,
        size: 50,
        opacity: 100
    }
});

const Watermark = () => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState(EmptySource());
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        watermark: false,
        resize: false,
        onSelect: (files: File[], argument: any) => handleFilesSelected(files, argument),
        onClose: () => handleFilesClose()
    });
    const [filePreview, setFilePreview] = useState<FilePreview>({
        total: 0,
        processing: false
    });

    // Zobrazenie súborov
    const handleFilesOpen = (target: string) => {
        setFilesDialog(prev => ({ ...prev, argument: target, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };
    const handleFilesSelected = (files: File[], argument: any) => {
        if (files.length > 0 && files[0].fileIsImage) {
            if (argument === 'full') {
                setSource(prev => ({ ...prev, full: { ...prev.full, fileId: files[0].id, file: files[0] } }))
            }
            else {
                setSource(prev => ({ ...prev, medium: { ...prev.medium, fileId: files[0].id, file: files[0] } }))
            }
        }
        handleFilesClose();
    };

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'watermark')
            .then(response => {
                setSource(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSave = () => {
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'watermark', source)
            .then(response => {
                if (response.data === true) {
                    setWarning({ ...EmptyWarning, color: 'success', text: 'Nastavenia boli uložené!' });
                }
                else {
                    setWarning({ ...EmptyWarning, color: 'error', text: 'Nastavenia sa nepodarilo uložiť!' });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChangePosition = (full: boolean, position: number) => {
        if (full) {
            setSource(prev => ({ ...prev, full: { ...prev.full, position: position } }));
        }
        else {
            setSource(prev => ({ ...prev, medium: { ...prev.medium, position: position } }));
        }
    };

    // Pri provom zobrazení zistím počet náhľadov, ktoré treba spracovať
    const loadFilePreviewTotal = () => {
        axios
            .get(AppConfig.ApiUri + 'file/preview')
            .then(response => {
                const total = (response.data ?? 0) as number;
                setFilePreview(prev => ({ ...prev, total: total }));
            });
    };
    useEffect(() => loadFilePreviewTotal(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Pri spustení spracovania označím všetky obrázky, že nemajú spracované náhľady
    const handleFilePreviewStart = () => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Generovanie náhľadov', children: 'Skutočne chcete spustiť generovanie všetkých náhľadov? Podľa počtu obrázkov môže tento proces trvať niekoľko minút až hodín. Počas celého procesu musíte ponechať túto sekciu otvorenú.', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                axios
                    .post(AppConfig.ApiUri + 'file/preview', null, {
                        params: {
                            preview: false
                        }
                    })
                    .then(response => {
                        const total = (response.data ?? 0) as number;
                        setFilePreview(prev => ({ ...prev, total: total, processing: (total > 0) }));
                    });
            }
        }));
    };

    // Po kliknutí na pokračovať spustím proces spracovanie zmenou stavu
    const handleFilePreviewContinue = () => {
        if (filePreview.total > 0 && !filePreview.processing) {
            setFilePreview(prev => ({ ...prev, processing: true }));
        }
    };

    // Funkcia pre vyvolanie spracovania dávky náhľadov, ktorá automaticky nastaví zostávajúci počet
    const handleFilePreviewGenerate = () => {
        axios
            .post(AppConfig.ApiUri + 'file/generatepreview')
            .then(response => {
                const total = (response.data ?? 0) as number;
                // Celkový počet nastavím len vtedy, ak ešte prebieha spracovania (medzi tým som to mohol zrušiť a odpoveď s neaktuálnym počtom prišla neskôr)
                setFilePreview(prev => ({ ...prev, total: (prev.processing ? total : 0) }));
            });
    };

    // Spracovanie ďalšej dávky bude automaticky odpalovať zmena zostávajúceho počtu (ak je aktívne spracovanie, teda processing=true)
    useEffect(() => {
        // Spracovanie dávky spúšťam pre isostotu s menšou prestávkou, aby som nepreťažoval server
        var timeout: any = null;
        // Ak zostávajú ešte súbory a prebieha proces spracovania, tak vyvolám spracovanie ďalšej dávky
        if (filePreview.total > 0 && filePreview.processing) {
            timeout = setTimeout(() => {
                handleFilePreviewGenerate();
            }, 500);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [filePreview.processing, filePreview.total]);

    // Pri zrušení spracovania označím všetky obrázky, že majú spracované náhľady a generovanie ukončím zmenou stavu
    const handleFilePreviewCancel = () => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Generovanie náhľadov', children: 'Skutočne chcete zrušiť generovanie náhľadov?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                // Ukončím proces
                setFilePreview(prev => ({ ...prev, total: 0, processing: false }));
                // Označím všetky obrázky, že už majú spracované náhľady
                axios
                    .post(AppConfig.ApiUri + 'file/preview', null, {
                        params: {
                            preview: true
                        }
                    });
            }
        }));
    };

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Súbory */}
            <FilesDialog {...filesDialog} />

            {/* Obsah */}
            <Content fullHeight={true}>
                <ContentPaperScroll>

                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={6} mb={3}>
                            <Typography variant="h4" gutterBottom component="div">
                                Veľký náhľad
                            </Typography>
                            <Typography>
                                Veľký náhľad sa zobrazuje po kliknutí malú fotku v zoznamoch.
                            </Typography>
                            <FormControlLabel control={<Switch checked={source.full?.actived} name="actived" onChange={(e) => { setSource(prev => ({ ...prev, full: { ...prev.full, actived: e.target.checked } })) }} />} label={source.full?.actived ? 'Aktívny vodoznak' : 'Vypnuté'} />
                            <Typography variant="h6" gutterBottom component="div" mt={2}>
                                Umiestnenie vodoznaku
                            </Typography>
                            <Grid container component={Paper} py={1} my={2} variant="outlined">
                                <Grid item xs={6} textAlign="left" pl={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(true, 0)} checked={source.full?.position === 0} />} label="Vľavo hore" />
                                </Grid>
                                <Grid item xs={6} textAlign="right" pr={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(true, 1)} checked={source.full?.position === 1} />} label="Vpravo hore" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(true, 4)} checked={source.full?.position === 4} />} label="V strede" />
                                </Grid>
                                <Grid item xs={6} textAlign="left" pl={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(true, 2)} checked={source.full?.position === 2} />} label="Vľavo dole" />
                                </Grid>
                                <Grid item xs={6} textAlign="right" pr={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(true, 3)} checked={source.full?.position === 3} />} label="Vpravo dole" labelPlacement="start" />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div" mt={2}>
                                    Veľkosť vodoznaku ({source?.full?.size}%)
                                </Typography>
                                <Typography>
                                    Veľkosť vodoznaku bude maximálne {source?.full?.size}% výšky alebo šírky obrázku.
                                </Typography>
                                <Slider value={source?.full?.size ?? 0} aria-label="Veľkosť vodoznaku" valueLabelDisplay="auto" step={10} min={10} max={100} onChange={(e, newvalue) => setSource(prev => ({ ...prev, full: { ...prev.full, size: newvalue as number } }))} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Viditeľnosť vodoznaku ({source?.full?.opacity}%)
                                </Typography>
                                <Typography>
                                    Menšie číslo znamená väčšiu priehľadnosť vodoznaku.
                                </Typography>
                                <Slider value={source?.full?.opacity ?? 0} aria-label="Veľkosť vodoznaku" valueLabelDisplay="auto" step={10} min={10} max={100} onChange={(e, newvalue) => setSource(prev => ({ ...prev, full: { ...prev.full, opacity: newvalue as number } }))} />
                            </Grid>


                            <Grid item xs={12} md={4}>
                                {(source.full?.fileId ?? 0) > 0 && (source.full?.file?.fileSrcSmall ?? '').length > 0 &&
                                    <Card sx={{ mt: 1 }}>
                                        <CardMedia component="img" height="120" image={source.full?.file?.fileSrcMedium} alt="Náhľad" sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }} />
                                        <CardActions>
                                            <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={(e) => { setSource(prev => ({ ...prev, full: { ...prev.full, fileId: 0 } })) }}>Vymazať</Button>
                                        </CardActions>
                                    </Card>
                                }
                                <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={() => handleFilesOpen('full')}>Vybrať obrázok</Button>
                            </Grid>

                        </Grid>

                        <Grid item xs={12} md={6} mb={3}>
                            <Typography variant="h4" gutterBottom component="div">
                                Malý náhľad
                            </Typography>
                            <Typography>
                                Malý náhľad sa najčastejšie zobrazuje ako malá fotka v zoznamoch.
                            </Typography>
                            <FormControlLabel control={<Switch checked={source.medium?.actived} name="actived" onChange={(e) => { setSource(prev => ({ ...prev, medium: { ...prev.medium, actived: e.target.checked } })) }} />} label={source.medium?.actived ? 'Aktívny vodoznak' : 'Vypnuté'} />
                            <Typography variant="h6" gutterBottom component="div" mt={2}>
                                Umiestnenie vodoznaku
                            </Typography>
                            <Grid container component={Paper} py={1} my={2} variant="outlined">
                                <Grid item xs={6} textAlign="left" pl={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(false, 0)} checked={source.medium?.position === 0} />} label="Vľavo hore" />
                                </Grid>
                                <Grid item xs={6} textAlign="right" pr={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(false, 1)} checked={source.medium?.position === 1} />} label="Vpravo hore" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(false, 4)} checked={source.medium?.position === 4} />} label="V strede" />
                                </Grid>
                                <Grid item xs={6} textAlign="left" pl={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(false, 2)} checked={source.medium?.position === 2} />} label="Vľavo dole" />
                                </Grid>
                                <Grid item xs={6} textAlign="right" pr={2}>
                                    <FormControlLabel control={<Radio onChange={(e) => handleChangePosition(false, 3)} checked={source.medium?.position === 3} />} label="Vpravo dole" labelPlacement="start" />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div" mt={2}>
                                    Veľkosť vodoznaku ({source?.medium?.size}%)
                                </Typography>
                                <Typography>
                                    Veľkosť vodoznaku bude maximálne {source?.medium?.size}% výšky alebo šírky obrázku.
                                </Typography>
                                <Slider value={source?.medium?.size ?? 0} aria-label="Veľkosť vodoznaku" valueLabelDisplay="auto" step={10} min={10} max={100} onChange={(e, newvalue) => setSource(prev => ({ ...prev, medium: { ...prev.medium, size: newvalue as number } }))} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Viditeľnosť vodoznaku ({source?.medium?.opacity}%)
                                </Typography>
                                <Typography>
                                    Menšie číslo znamená väčšiu priehľadnosť vodoznaku.
                                </Typography>
                                <Slider value={source?.medium?.opacity ?? 0} aria-label="Veľkosť vodoznaku" valueLabelDisplay="auto" step={10} min={10} max={100} onChange={(e, newvalue) => setSource(prev => ({ ...prev, medium: { ...prev.medium, opacity: newvalue as number } }))} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                {(source.medium?.fileId ?? 0) > 0 && (source.medium?.file?.fileSrcSmall ?? '').length > 0 &&
                                    <Card sx={{ mt: 1 }}>
                                        <CardMedia component="img" height="120" image={source.medium?.file?.fileSrcMedium} alt="Náhľad" sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }} />
                                        <CardActions>
                                            <Button variant="text" color="secondary" size="medium" startIcon={<DeleteIcon />} onClick={(e) => { setSource(prev => ({ ...prev, medium: { ...prev.medium, fileId: 0 } })) }}>Vymazať</Button>
                                        </CardActions>
                                    </Card>
                                }
                                <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={() => handleFilesOpen('medium')}>Vybrať obrázok</Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* Priebeh generovania náhľadov, zruešenie / pokračovanie generovanie */}
                    {filePreview.total > 0 && (
                        <>
                            <Alert severity="warning" sx={{ mt: 2 }}>
                                <AlertTitle>Generovanie náhľadov (zostáva: {filePreview.total})</AlertTitle>
                                {filePreview.processing && (
                                    <>
                                        Prebieha generovanie náhľadov, ponechajte otvorenú túto stránku kým bude prebiehať generovanie alebo kliknite na tlačidlo "zrušiť".
                                    </>
                                )}
                                {!filePreview.processing && (
                                    <>
                                        Je potrebné dokončiť proces generovania náhľadov, pre pokračovanie v generovaní kliknite na tlačidlo "pokračovať", alebo ho zrušte tlačidlom "zrušiť".
                                    </>
                                )}
                            </Alert>

                            {filePreview.processing && (<LinearProgress sx={{ mt: 1 }} />)}

                            <Button fullWidth onClick={handleFilePreviewContinue} disabled={filePreview.processing} variant="contained" size="large" color="error" sx={{ mt: 1 }}>Pokračovať</Button>
                            <Button fullWidth onClick={handleFilePreviewCancel} variant="outlined" size="large" color="error" sx={{ mt: 1 }}>Zrušiť</Button>
                        </>
                    )}

                    {filePreview.total === 0 && (
                        <>
                            <Button disabled={loading} onClick={handleSave} variant="contained" size="large">Uložiť</Button>
                            <Button disabled={loading} onClick={handleFilePreviewStart} variant="outlined" size="large" color="error" sx={{ ml: 1 }}>Generovať náhľady</Button>
                        </>
                    )}

                    {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                </ContentPaperScroll>
            </Content>
        </>
    )
}

export default Watermark;