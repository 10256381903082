import { FormEvent, useEffect, useMemo, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { StockMovementComputeRequest, StockMovementComputeResult, Warehouse } from '../../models/Models';

// Utility
import { ConvertToInt } from '../../utility/Number';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, FormControl, InputLabel, MenuItem, Select, Typography, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import { NullMinDate } from '../../utility/Date';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface ProductsStockComputeProps {
    open: boolean;
    keepMounted?: boolean;
    warehouses: Warehouse[];
    onClose: () => void;
}

interface ComputeState {
    warehouseIds: number[]; // sklady pre spracovanie (fronta)
    warehouseId: number; // aktuálny sklad 
    productId: number; // posledný spracovaný promodukt
    totalCount: number; // zostávajúci počet produktov
    processing: boolean; // prebieha spracovanie
}

const ProductsStockCompute = (props: ProductsStockComputeProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [warehouseId, setWarehouseId] = useState<number>(0);
    const [date, setDate] = useState<Date | undefined>(undefined);
    const [computeState, setComputeState] = useState<ComputeState>({
        warehouseIds: [],
        warehouseId: 0,
        productId: 0,
        totalCount: 0,
        processing: false
    })

    // Spustenie prepočtu
    const handleStart = () => {
        if (computeState.processing === true) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Spustiť prepočet', children: <>Skutočne chcete spustiť prepočet skladu?<Alert severity='warning' sx={{ mt: 2, textAlign: 'left' }}>Podľa počtu skladových kariet môže tento proces trvať niekoľko minút až hodín. Je odporúčané ho vykonávať v čase, keď je systém minimálne zaťažený (mimo aktívny čas používania). Počas celého procesu musíte ponechať okno otvorené.</Alert></>, onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setComputeState(prev => ({
                    ...prev,
                    warehouseIds: (warehouseId > 0 ? [warehouseId] : props.warehouses.map(warehouse => warehouse.id ?? 0)),
                    warehouseId: 0,
                    productId: 0,
                    totalCount: 0,
                    processing: true
                }));
            }
        }));
    }

    // Ukončenie prepočtu
    const handleStop = () => {
        if (computeState.processing === false) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Zastaviť prepočet', children: 'Skutočne chcete zastaviť prepočet skladu?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setComputeState(prev => ({ ...prev, processing: false }));
            }
        }));
    }

    // Prepočet
    const computeNext = async () => {
        // Nepokračujem, ak prepočet nie je aktívny
        if (computeState.processing === false) {
            return;
        }

        // Prechádzam na ďalší sklad
        if (computeState.warehouseId === 0) {
            setComputeState(prev => {
                // Získam ďalší sklad v poradí
                var warehouseIdNext = prev.warehouseIds.length > 0 ? prev.warehouseIds[0] : 0;
                return {
                    ...prev,
                    warehouseIds: prev.warehouseIds.filter(id => id !== warehouseIdNext), // odstránim z fronty
                    warehouseId: warehouseIdNext, // nastavím nový sklad
                    productId: 0, // začínam od nuly
                    processing: warehouseIdNext > 0 // pokračujem len ak bol najdený sklad
                };
            });

            // Ďalej nepokračujem, funkcia bude opätnovne vyvolaná po nastavení skladu
            return;
        }

        // Vyvolám prepočet ďalšieho produktu a zapíšem si posledné id
        axios
            .post(AppConfig.ApiUri + 'stockmovement/compute/', {
                date: date,
                warehouseId: computeState.warehouseId,
                productId: computeState.productId
            } as StockMovementComputeRequest)
            .then(response => {
                if (response.data !== null) {
                    var result = response.data as StockMovementComputeResult;
                    setComputeState(prev => ({
                        ...prev,
                        warehouseId: (result.count > 0 ? prev.warehouseId : 0), // ak v poradí nie sú ďalšie produkty, tak prechádzam na ďalší sklad
                        productId: result.productId, // posledný spracovaný produkt (ďalší cyklus pokračuje na ďalší id > lastId)
                        totalCount: result.count // zostávajúci počet
                    }));
                }
            });
    };
    useEffect(() => { computeNext(); }, [computeState]);

    // Názov skladu, ktorý sa aktuálne prepočítava
    const warehouseName = useMemo<string>(() => props.warehouses?.find(warehouse => warehouse.id === computeState.warehouseId)?.name ?? '', [computeState.warehouseId]);

    return (
        <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xs" fullWidth open={props.open} scroll="body" onClose={(e, r) => {
            if (r !== 'backdropClick') {
                // Ak prebieha spracovanie tak vyvolám stop, ak nie tak rovno zavriem okno
                if (computeState.processing === true) {
                    handleStop();
                } else {
                    props.onClose();
                }
            }
        }}>
            <Box component="form" onSubmit={(event?: FormEvent<HTMLFormElement>) => {
                if (event !== undefined) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                handleStart();
            }}>
                <DialogTitle>
                    {computeState.processing === true ? 'Prebieha prepočet skladu' : 'Prepočítať sklady'}
                </DialogTitle>
                <DialogContent sx={{ maxHeight: '75vh' }}>
                    <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                        {computeState.processing === false && (
                            <>
                                <Grid item xs={12}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="warehouse-label">Sklad</InputLabel>
                                        <Select labelId='warehouse-label' label="Sklad" margin="dense" value={warehouseId ?? 0} fullWidth onChange={(e) => setWarehouseId(ConvertToInt(e.target.value) ?? 0)}>
                                            <MenuItem value={0}>Všetky sklady</MenuItem>
                                            {props.warehouses?.filter(warehouse => (warehouse.type ?? 0) === 0)?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                        <FormControl margin="dense" fullWidth>
                                            <DesktopDateTimePicker format='dd.MM.yyyy HH:mm' timeSteps={{ minutes: 5 }} ampm={false} label="Obdobie od" value={NullMinDate(date)} onChange={(d) => {
                                                var value = d as any;
                                                if (value !== null && isNaN(value)) {
                                                    return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
                                                }
                                                setDate(d as Date);
                                            }} />
                                        </FormControl>
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} mt={1}>
                                    <Alert severity='info'>
                                        Sklady sa prepočítavajú automaticky pri každej zmene v pohyboch. Nie je potrebné vykonávať prepočet, pokiaľ údaje neboli poškodené napríklad výpadkom.
                                    </Alert>
                                </Grid>
                            </>
                        )}

                        {computeState.processing === true && (
                            <>
                                <Grid item xs={12}>
                                    <Typography align='center' variant='body2' mt={2}>Aktuálny sklad</Typography>
                                    <Typography align='center' variant='h6'>{warehouseName}</Typography>
                                    <Typography align='center' variant='body2' mt={2}>Zostávajúci počet skladových kariet</Typography>
                                    <Typography align='center' variant='h6'>{computeState.totalCount}</Typography>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Button startIcon={<CircularProgress size={16} color="inherit" />} onClick={() => handleStop()} fullWidth variant='contained' color='error'>Zastaviť prepočet</Button>
                                </Grid>
                            </>
                        )}

                        {(warning.text.length > 0 && <Grid item xs={12}><Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert></Grid>)}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={computeState.processing === true} onClick={props.onClose}>Späť</Button>
                    <Button disabled={computeState.processing === true} type="submit" variant="contained">Spustiť prepočet</Button>
                </DialogActions>
            </Box>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

        </Dialog>
    )
}

export default ProductsStockCompute;