import { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';
import { FileOpen } from '../../utility/File';
import { NullMinDate } from '../../utility/Date';

// Modely
import { Currency, CustomerLite, CustomersFilter, ExternalDocument, DocumentType, File, Modules } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, Box, InputLabel, MenuItem, Select, Typography, Accordion, AccordionDetails, AccordionSummary, IconButton, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FilesDialog, { FilesDialogProps } from '../file/FilesDialog';
import CustomFieldValues from '../settings/custom_field/CustomFieldValues';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import CustomersDialog, { CustomersDialogProps } from '../customer/CustomersDialog';
import ExternalDocumentCreateRelated from './ExternalDocumentCreateRelated';
import skLocale from 'date-fns/locale/sk';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import NumbersIcon from '@mui/icons-material/Numbers';
import EuroIcon from '@mui/icons-material/Euro';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import PrintIcon from '@mui/icons-material/Print';
import PersonIcon from '@mui/icons-material/Person';
import ContactsIcon from '@mui/icons-material/Contacts';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): ExternalDocument => ({
    id: 0,
    externalDocumentTypeId: 0,
    done: false,
    paid: false,
    number: '',
    price: 0,
    currencyCode: '',
    currencyExchange: 1,
    note: '',
    fileId: 0,
    symbolVariable: '',
    symbolSpecific: '',
    symbolConstant: ''
});

// Vstupné parametre
export interface ExternalDocumentCreateProps {
    open: boolean;
    id?: number;
    domainId?: number;                          // Doména len pre automatické dosadenie fakturačných údajov
    externalDocument?: ExternalDocument;        // Predvolené nastavenia dokladu
    externalDocumentTypeId: number;
    externalDocumentTypeName: string;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: (id: number) => void;
    onClose: () => void;
}

const ExternalDocumentCreate = (props: ExternalDocumentCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [source, setSource] = useState<ExternalDocument>(EmptySource());
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [currency, setCurrency] = useState<Currency>({}); // Hlavná systémová mena
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);

    // Sledovanie zmien - aktivované oneskorene, aby sa stihol zmeniť stav "source"
    const [sourceChanged, setSourceChanged] = useState<boolean | undefined>(undefined);
    const setSourceStartWatch = () => { setTimeout(() => { setSourceChanged(false); }, 250); };
    const setSourceStopWatch = () => { setSourceChanged(undefined); };
    useEffect(() => {
        if (sourceChanged === false) {
            setSourceChanged(true);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Súbory
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        directoryType: 3, // Predvolený adresár pre doklady
        onSelect: (files: File[]) => {
            if (files.length > 0) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileExtension: files[0].fileExtension,
                        fileIsImage: files[0].fileIsImage,
                        fileName: files[0].fileName,
                        fileSrc: files[0].fileSrc,
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Výber existujúceho partnera
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[], argument: any) => {
            if (customers.length > 0) {
                setSource(prev => {
                    return ({
                        ...prev, 
                        customerId: customers[0].id ?? 0, 
                        customerName: (customers[0].person ?? false) === true ? (customers[0].firstname ?? '') + ' ' + (customers[0].lastname ?? '') : (customers[0].company ?? '')
                    });
                });
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });

    // Zobrazenie partnerov
    const handleCustomersOpen = (argument: any, filter?: CustomersFilter) => {
        setCustomersDialog(prev => ({ ...prev, open: true, argument: argument, filter: filter }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };

    // Reset formuláru
    const handleReset = (keepId?: boolean) => {
        // K resetovaným údajom (resetuje sa pri zavrení) pridám aj vstupné údaje (stav musím zachovať, keďže som medzi tým automaticky vložil fakturačné údaje)
        setSource(prev => ({
            ...EmptySource(), //ako prvé
            id: (keepId ?? false) === true ? prev.id : 0,
            externalDocumentTypeId: props.externalDocumentTypeId,
            currencyCode: (currencies.find(currency => currency.system === true)?.code ?? ''),
            currencyExchange: 1,
            ...props.externalDocument ?? {} //ako posledné
        }));
        setSourceStartWatch();
    };
    const handleResetConfirm = (keepId?: boolean) => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Reset', children: 'Skutočne chcete resetovať celý formulár?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                handleReset(keepId);
            }
        }));
    }

    // Načítam zoznam typov
    const loadDocumentTypes = () => {
        if (documentTypes.length > 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'documenttype')
            .then(response => {
                setDocumentTypes((response.data as DocumentType[]).filter(item => item.actived === true));
            });
    };
    useEffect(() => loadDocumentTypes(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam zoznam mien
    const loadCurrencies = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'currency')
            .then(response => {
                setCurrencies(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadCurrencies(), []);

    // Ak sa zmení číselník meny, tak automaticky získam hlavnú systémovú menu
    useEffect(() => {
        setCurrency((currencies.find(currency => currency.system === true) ?? {}));
    }, [currencies]);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            setSourceStopWatch();
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'externaldocument/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    // Nedovolím zobraziť editáciu cudzieho typu dokladu
                    if ((response.data as ExternalDocument).externalDocumentTypeId !== props.externalDocumentTypeId) {
                        props.onClose();
                        return;
                    }
                    setSource(response.data as ExternalDocument);
                    setSourceStartWatch();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any, type?: string) => {
        if (type === 'date' && value !== null && isNaN(value)) {
            return; // Dátum - nemením hodnotu pri čiastočne zadanom dátume "Invalid Date" (kontrolovaný vstup by sa nedal prepísať)
        }
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (source.externalDocumentTypeId === 0) {
            setWarning({ ...EmptyWarning, name: 'externalDocumentTypeId', text: 'Musíte vybrať druh dokladu!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'externaldocument', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave(response.data as number);
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Stiahnuť / Vytlačiť
    const handleDownload = (fileSrc: string) => {
        if (fileSrc.length > 0) {
            FileOpen(fileSrc, '_blank');
        }
    };


    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => {
                if (r !== 'backdropClick') {
                    if (sourceChanged !== true) {
                        props.onClose();
                        return;
                    }
                    setConfirm(prev => ({
                        ...prev, open: true, title: 'Neuložené zmeny', children: 'Skutočne chcete zavrieť okno bez uloženia zmien?', onConfirm: () => {
                            setConfirm(prev => ({ ...prev, open: false }));
                            props.onClose();
                        }
                    }));
                }
            }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.externalDocumentTypeName} / {((props?.id ?? 0) > 0 ? (source.number ?? '-') : 'Nový doklad')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="flex-end">

                            <Grid item xs={12} sm={4}>
                                <FormControlLabel control={<Switch checked={source.done} name="done" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Vybavené" />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <FormControlLabel control={<Switch checked={source.paid} name="paid" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label='Uhradené' />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField required margin="dense" name="number" label="Číslo (externé)" fullWidth variant="outlined" autoComplete="off" value={source.number ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'number' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 32 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDatePicker format='dd.MM.yyyy' label="Dátum vystavenia" value={NullMinDate(source.dateOfIssue)} onChange={(d) => { handleChange('dateOfIssue', d, 'date') }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDatePicker format='dd.MM.yyyy' label="Dátum splatnosti" value={NullMinDate(source.dateOfDue)} onChange={(d) => { handleChange('dateOfDue', d, 'date') }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDatePicker format='dd.MM.yyyy' label="Dátum zdaniteľného plnenia" value={NullMinDate(source.dateOfTaxLiability)} onChange={(d) => { handleChange('dateOfTaxLiability', d, 'date') }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDatePicker format='dd.MM.yyyy' label="Dátum dodania" value={NullMinDate(source.dateOfDelivery)} onChange={(d) => { handleChange('dateOfDelivery', d, 'date') }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={skLocale}>
                                    <FormControl margin="dense" fullWidth>
                                        <DesktopDatePicker format='dd.MM.yyyy' label="Dátum platnosti" value={NullMinDate(source.dateOfExpiration)} onChange={(d) => { handleChange('dateOfExpiration', d, 'date') }} />
                                    </FormControl>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="price" type="text" label="Suma" variant="outlined" autoComplete="off" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'price' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="domain-create-currency">Mena</InputLabel>
                                    <Select required labelId="domain-create-currency" label="Mena" value={source.currencyCode ?? 0} name="currencyCode" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'currencyCode' && setFocus(input)}>
                                        {currencies?.map(item => <MenuItem key={item.id} value={item.code}>{item.code}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="currencyExchange" type="text" label="Kurz" variant="outlined" autoComplete="off" value={source.currencyExchange ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    disabled={(source.currencyCode === currency.code)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('partner')} onChange={() => handleToggleAccordion('partner')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Partner ({((source.customerId ?? 0) > 0 ? (source.customerName ?? '') : 'nie')})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField margin="dense" label="Vybraný partner" fullWidth variant="outlined" autoComplete="off" disabled 
                                                    value={((source.customerId ?? 0) > 0 ? (source.customerName ?? '') : '...')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <PersonIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12} mb={1}>
                                                <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => handleCustomersOpen('')}>Vybrať</Button>
                                                {(source.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (source.customerId ?? 0).toString()} target="_blank">Detail</Button>}
                                                {(source.customerId ?? 0) > 0 && <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={() => { setSource(prev => ({ ...prev, customerId: 0 })) }}>Zrušiť prepojenie</Button>}
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('file')} onChange={() => handleToggleAccordion('file')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Príloha ({(source.fileId ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                {(source.fileId ?? 0) > 0 ?
                                                    <Alert
                                                        variant="standard"
                                                        severity="info"
                                                        icon={<DescriptionIcon />}
                                                        onDoubleClick={() => handleDownload(source.other?.fileSrc ?? '')}
                                                        action={
                                                            <>
                                                                <IconButton aria-label="Tlačiť" title="Tlačiť" size="small" onClick={() => handleDownload(source.other?.fileSrc ?? '')}>
                                                                    <PrintIcon fontSize="small" />
                                                                </IconButton>
                                                                <IconButton aria-label="Vymazať" size="small" onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>
                                                                    <DeleteIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </>
                                                        }>
                                                        Doklad {source.other?.fileExtension !== undefined ? '(' + source.other?.fileExtension + ')' : ''}
                                                    </Alert>
                                                    :
                                                    <Alert variant="standard" severity="warning"> Nie je vložená žiadna príloha k dokladu. Pre externé doklady nie je možné generovať tlačové zostavy, nakoľko účelom tejto evidencie je uchovávať originálny náhľad externého dokladu (fotokópia, pdf a podobne).</Alert>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať súbor</Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('symbols')} onChange={() => handleToggleAccordion('symbols')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Symboly (VS: {source.symbolVariable}, SS: {source.symbolSpecific}, KS: {source.symbolConstant})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField margin="dense" name="symbolVariable" label="Variabilný symbol (automaticky)" fullWidth variant="outlined" autoComplete="off" value={source.symbolVariable ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'symbolVariable' && setFocus(input)}
                                                    inputProps={{ maxLength: 10 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <NumbersIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField margin="dense" name="symbolSpecific" label="Špecifický symbol" fullWidth variant="outlined" autoComplete="off" value={source.symbolSpecific ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'symbolSpecific' && setFocus(input)}
                                                    inputProps={{ maxLength: 10 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <NumbersIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField margin="dense" name="symbolConstant" label="Konštantný symbol" fullWidth variant="outlined" autoComplete="off" value={source.symbolConstant ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'symbolConstant' && setFocus(input)}
                                                    inputProps={{ maxLength: 4 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <NumbersIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: false }} expanded={isAccordionExpanded('related')} onChange={() => handleToggleAccordion('related')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Doklady ({(source.relatedDocuments?.length ?? 0)})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <ExternalDocumentCreateRelated source={source} setSource={setSource} documentTypes={documentTypes} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputProps={{ maxLength: 1000 }}
                                                    inputRef={input => { props.autoFocus === 'note' && setFocus(input) }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <CustomFieldValues moduleId={Modules.ExternalDocument} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => handleResetConfirm()} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Výber z adresára (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
            <CustomersDialog {...customersDialog} />

            <FilesDialog {...filesDialog} />
        </>
    )
}

export default ExternalDocumentCreate;