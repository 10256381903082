import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { CustomerLite, ProductSupplier, Unit } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../utility/Number';

// Komponenty
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertColor, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CustomersDialog, { CustomersDialogProps } from '../customer/CustomersDialog';

// Ikony
import EuroIcon from '@mui/icons-material/Euro';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactsIcon from '@mui/icons-material/Contacts';
import PersonIcon from '@mui/icons-material/Person';
import CalculateIcon from '@mui/icons-material/Calculate';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name?: string;
    text?: string;
    color?: string;
}

export interface ProductCreateSupplierEditProps {
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    units: Unit[];
    vatRates: number[];
    supplier: ProductSupplier;
    suppliers: ProductSupplier[]; // aby som mohol vyvolať edit v prípade, že vyberiem už existujúceho dodávateľa
    onSave: (supplier: ProductSupplier) => void;
    onClose: () => void;
}

const ProductCreateSupplierEdit = (props: ProductCreateSupplierEditProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>({});
    const [source, setSource] = useState<ProductSupplier>({});

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if ((warning.text?.length ?? 0) > 0) {
            setWarning({});
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pri otvorení vyplním formulár
    useEffect(() => {
        if (!props.open) {
            setAccordionExpanded([]);
            return;
        }
        setSource(props.supplier);
        // Automaticky zobrazím okno s výberom dodávateľa
        if ((props.supplier?.customerId ?? 0) === 0) {
            handleCustomersOpen();
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning({});

        if ((source.customerId ?? 0) === 0) {
            setWarning({ color: 'error', text: 'Musíte vybrať partnera!' });
            return;
        }

        props.onSave(source);
        props.onClose();
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Výber a zrušenie partnera
    const [customersDialog, setCustomersDialog] = useState<CustomersDialogProps>({
        open: false,
        keepMounted: false, // Ponechať vypnuté! (mohol by cyklicky vykreslovať sám seba, pri prvom zobrazení to v prípade potreby môžem jednorázovo prepnúť)
        onSelect: (customers: CustomerLite[]) => {
            if (customers.length > 0) {
                if ((customers[0]?.id ?? 0) > 0) {

                    // Ak pridávam nový záznam a dodávateľ už v zozname figuruje, tak vyvolám edit záznamu
                    if ((source.customerId ?? 0) === 0) {
                        var supplier: ProductSupplier | undefined = props.suppliers.find(item => item.customerId === customers[0]?.id);
                        if (supplier !== undefined) {
                            setSource(supplier);
                            handleCustomersClose();
                            return;
                        }
                    }

                    // Nastavím vybraného dodávateľa
                    setSource(prev => ({
                        ...prev,
                        customerId: customers[0]?.id ?? 0,
                        customerName: customers[0]?.name ?? ''
                    }));
                }
            }
            handleCustomersClose();
        },
        onClose: () => handleCustomersClose()
    });
    const handleCustomersOpen = () => {
        setCustomersDialog(prev => ({ ...prev, open: true, keepMounted: true }));
    };
    const handleCustomersClose = () => {
        setCustomersDialog(prev => ({ ...prev, open: false }));
    };
    const handleCustomerRelationCancel = () => {
        setSource(prev => ({
            ...prev,
            customerId: 0,
            customerName: ''
        }))
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Dodávateľ
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} md={8}>
                                <TextField required margin="dense" label="Existujúci partner" fullWidth variant="outlined" autoComplete="off" disabled
                                    value={((source.customerId ?? 0) > 0 ? source.customerName : '...')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="supplier-type">Typ partnera</InputLabel>
                                    <Select required labelId="supplier-type" label="Typ partnera" value={source.customerType ?? 0} name="customerType" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'customerType' && setFocus(input)}>
                                        <MenuItem value={0}>Dodávateľ</MenuItem>
                                        <MenuItem value={1}>Výrobca</MenuItem>
                                        <MenuItem value={2}>Iné</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" size="small" startIcon={<ContactsIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleCustomersOpen}>Vybrať</Button>
                                {(source.customerId ?? 0) > 0 && <>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} href={AppRouteUrl.CUSTOMERS + '?id=' + (source.customerId ?? 0).toString()} target="_blank">Detail</Button>
                                    <Button variant="outlined" size="small" color="secondary" sx={{ mt: 1, mr: 1 }} onClick={handleCustomerRelationCancel}>Zrušiť prepojenie</Button>
                                </>}
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12} md={8}>
                                <TextField margin="dense" name="name" label="Názov produktu (dodávateľský)" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'name' && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField margin="dense" name="code" label="Kód produktu (dodávateľský)" fullWidth variant="outlined" autoComplete="off" value={source.code ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'code' && setFocus(input)}
                                    inputProps={{ maxLength: 64 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField fullWidth margin="dense" name="price" type="text" label='Cena (bez DPH)' variant="outlined" autoComplete="off" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => (props.autoFocus === 'price' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="product-create-vatrate">DPH</InputLabel>
                                    <Select required labelId="product-create-vatrate" label="DPH" value={source.priceVatRate ?? 0} name="priceVatRate" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'priceVatRate' && setFocus(input)}>
                                        {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                        {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField fullWidth margin="dense" name="minimalQuantity" type="text" label='Minimálne obj. množstvo' variant="outlined" autoComplete="off" value={source.minimalQuantity ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'minimalQuantity' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalculateIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField fullWidth margin="dense" name="maximalQuantity" type="text" label='Maximálne obj. množstvo' variant="outlined" autoComplete="off" value={source.maximalQuantity ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'maximalQuantity' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CalculateIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField fullWidth margin="dense" name="deliveryTime" type="number" label="Dodacia lehota (dni)" variant="outlined" value={source.deliveryTime ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'deliveryTime' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocalShippingIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="product-create-unit">Merná jednotka</InputLabel>
                                    <Select labelId="product-create-unit" label="Merná jednotka" value={source.unitId ?? ''} name="unitId" onChange={(e) => {
                                        const unitId = parseInt(e.target.value.toString());
                                        const unitName = props.units.find(unit => unit.id === unitId)?.other?.translationName ?? '';
                                        handleChange('unitId', unitId);
                                        handleChange('unitName', unitName);
                                    }}
                                        inputRef={input => (props.autoFocus === 'unitId' || props.autoFocus === 'unitName') && setFocus(input)}>
                                        <MenuItem value="0">-</MenuItem>
                                        {props.units.map(unit => (<MenuItem key={unit.id} value={unit.id}>{unit.other?.translationName ?? ''}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka ({(source.note?.length ?? 0) > 0 ? 'áno' : 'nie'})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                    inputProps={{ maxLength: 1000 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            {((warning.text?.length ?? 0) > 0 && <Grid item xs={12} mt={2}><Alert severity={(warning.color ?? 'error') as AlertColor}>{warning.text ?? ''}</Alert></Grid>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSource({})} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>

                {/* Výber zákazníka (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
                <CustomersDialogMemoized {...customersDialog} />

            </Dialog>
        </>
    )
}

export default ProductCreateSupplierEdit

const CustomersDialogMemoized = React.memo((props: CustomersDialogProps) => {
    return (
        <CustomersDialog {...props} />
    )
});