import React, { useEffect, useMemo, useState } from 'react';

// Modely
import { ProductLite, StockMovementsFilter, Warehouse } from '../../models/Models';

// Utility
import { FormatDecimal } from '../../utility/Format';

// Komponenty
import { Alert, Box, Button, Drawer, IconButton, Link, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

// Ikony
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const StyledTable = styled(Table)(({ theme }) => ({
    '& td, & th': {
        position: 'relative', paddingLeft: 2, paddingRight: 2
    },
    '& tbody tr:last-child td, & tbody tr:last-child th': {
        border: 0
    },
    '& tbody tr:hover': {
        background: 'rgba(0,0,0,.04)'
    }
}));

export interface ProductsDetailProps {
    product?: ProductLite;
    warehouses: Warehouse[];
    warehouseIdHighlight?: number;
    keepMounted?: boolean;
    onOpenStockMovements: (filter?: StockMovementsFilter) => void;
    onClose: () => void;
}

const ProductsDetail = (props: ProductsDetailProps) => {

    // Skupiny skladov
    const [warehouseGroups, setWarehouseGroups] = useState<string[]>([]);

    // Automaticky získam zoznam skupín
    useEffect(() => setWarehouseGroups(Array.from(new Set(props.warehouses?.map(item => item.warehouseGroupName ?? '') ?? []))), [props.warehouses]);

    // Upozornenie na limity
    const stockLimitWarning = (min?: number, max?: number, quantity?: number): boolean => {
        return ((min ?? 0) > 0 && (quantity ?? 0) < (min ?? 0)) ||
            ((max ?? 0) > 0 && (quantity ?? 0) > (max ?? 0));
    }

    // Sumáre
    const totalQuantity = (props.product?.stocks?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0) ?? 0);
    const totalReservedQuantity = (props.product?.stocks?.reduce((sum, current) => sum + (current?.reservedQuantity ?? 0), 0) ?? 0);
    const totalOrderedQuantity = (props.product?.stocks?.reduce((sum, current) => sum + (current?.orderedQuantity ?? 0), 0) ?? 0);

    // Celková hodnota zásob
    const stockValuation = useMemo<number>(() => props.product?.stocks?.reduce((sum, current) => sum + ((current?.priceWac ?? 0) * (current?.quantity ?? 0)), 0) ?? 0, [props.product?.stocks]);

    return (
        <Drawer sx={{ zIndex: 1399 }} keepMounted={props.keepMounted ?? false} anchor="right" open={props.product !== undefined} onClose={props.onClose} PaperProps={{ sx: { width: '90%', maxWidth: '550px', p: 0, pt: 0 } }}>
            <Box sx={{ position: 'sticky', top: 0, pt: 2, p: 2, pb: 1, zIndex: 9999, borderBottom: '1px solid rgba(0,0,0,.08)' }}>
                <Box sx={{ position: 'absolute', top: '12px', right: '12px' }}>
                    <IconButton aria-label="close" onClick={() => props.onClose()} sx={{ border: '1px solid #f0f0f0', ml: 0.5 }}><CloseIcon /></IconButton>
                </Box>
                <Typography mb={1} variant="h5" noWrap sx={{ pr: '50px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {props.product?.name}
                </Typography>
            </Box>
            <Box sx={{ px: 2 }}>

                <Button sx={{ mt: 2, float: 'right' }} variant='contained' color='secondary' size='small' onClick={() => props.onOpenStockMovements({ branchId: 0, departmentId: 0, warehouseId: 0 })} startIcon={<SwapVertIcon />}>Zobraziť pohyby</Button>

                <Typography variant='h6' mt={2}>Skladové zásoby</Typography>

                <StyledTable size="small" sx={{ mt: 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sklad</TableCell>
                            <TableCell align='right' sx={{ width: '20%' }}>Množstvo</TableCell>
                            <TableCell align='right' sx={{ width: '20%' }}>Rezervované</TableCell>
                            <TableCell align='right' sx={{ width: '20%' }}>Objednané</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {warehouseGroups?.map((groupName, index) => (
                            <React.Fragment key={index}>
                                {props.warehouses?.filter(stock => (stock.warehouseGroupName ?? '') === groupName)?.map((stock, index) => {
                                    var stockItem = props.product?.stocks?.find(item => item.warehouseId === stock.id);
                                    return (
                                        <React.Fragment key={stock.id}>
                                            <TableRow sx={(theme) => ({
                                                '& td': {
                                                    color: ((stockItem?.quantity ?? 0) > 0 ? theme.palette.success.main : theme.palette.error.main),
                                                    backgroundColor: (props.warehouseIdHighlight === stock.id ? 'rgba(247,255,0,.1)' : undefined)
                                                }
                                            })}>
                                                <TableCell>
                                                    {(stock.type ?? 0) === 0 ? (
                                                        <Link component="button" onClick={() => props.onOpenStockMovements({ branchId: 0, departmentId: 0, warehouseId: stock.id })} sx={{ color: 'inherit', textDecorationColor: 'inherit' }}>
                                                            {groupName.length > 0 ? groupName + ' / ' : ''}
                                                            {stock.name}
                                                        </Link>
                                                    ) : (
                                                        <>
                                                            {groupName.length > 0 ? groupName + ' / ' : ''}
                                                            {stock.name}
                                                        </>
                                                    )}
                                                    {stockLimitWarning(stockItem?.minimalQuantity, stockItem?.maximalQuantity, stockItem?.quantity) === true && (
                                                        <WarningIcon titleAccess={'Dosiahnutý limit od: ' + ((stockItem?.minimalQuantity ?? 0) > 0 ? stockItem?.minimalQuantity : '-') + ' do: ' + ((stockItem?.maximalQuantity ?? 0) > 0 ? stockItem?.maximalQuantity : '-')} color='error' sx={{ position: 'absolute', top: 3, right: 0, zIndex: 5 }} />
                                                    )}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {stockItem?.quantity ?? 0}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {stockItem?.reservedQuantity ?? 0}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {stockItem?.orderedQuantity ?? 0}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                })}
                            </React.Fragment>
                        ))}
                        <TableRow sx={(theme) => ({ '& td': { color: totalQuantity > 0 ? theme.palette.success.main : theme.palette.error.main } })}>
                            <TableCell>
                                <Link component="button" onClick={() => props.onOpenStockMovements({ branchId: 0, departmentId: 0, warehouseId: 0 })} sx={{ color: 'inherit', textDecorationColor: 'inherit' }}>
                                    Celkom
                                </Link>
                                {stockLimitWarning(props.product?.stockMinimalQuantity, props.product?.stockMaximalQuantity, totalQuantity) === true && (
                                    <WarningIcon titleAccess={'Dosiahnutý limit od: ' + ((props.product?.stockMinimalQuantity ?? 0) > 0 ? props.product?.stockMinimalQuantity : '-') + ' do: ' + ((props.product?.stockQuantity ?? 0) > 0 ? props.product?.stockQuantity : '-')} color='error' sx={{ position: 'absolute', top: 3, right: 0, zIndex: 5 }} />
                                )}
                            </TableCell>
                            <TableCell align='right'>{totalQuantity}</TableCell>
                            <TableCell align='right'>{totalReservedQuantity}</TableCell>
                            <TableCell align='right'>{totalOrderedQuantity}</TableCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>

                <Typography variant='h6' mt={2}>Skladová cena</Typography>

                <StyledTable size="small" sx={{ mt: 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sklad</TableCell>
                            <TableCell align='right' sx={{ width: '20%' }}>Skladová cena</TableCell>
                            <TableCell align='right' sx={{ width: '20%' }}>Posledný nákup</TableCell>
                            <TableCell align='right' sx={{ width: '20%' }}>Ocenenie zásob</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {warehouseGroups?.map((groupName, index) => (
                            <React.Fragment key={index}>
                                {props.warehouses?.filter(stock => (stock.type ?? 0) === 0 && (stock.warehouseGroupName ?? '') === groupName)?.map((stock, index) => {
                                    var stockItem = props.product?.stocks?.find(item => item.warehouseId === stock.id);
                                    return (
                                        <React.Fragment key={stock.id}>
                                            <TableRow sx={(theme) => ({
                                                '& td': {
                                                    backgroundColor: (props.warehouseIdHighlight === stock.id ? 'rgba(247,255,0,.1)' : undefined)
                                                }
                                            })}>
                                                <TableCell>
                                                    {groupName.length > 0 ? groupName + ' / ' : ''}
                                                    {stock.name}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {FormatDecimal(stockItem?.priceWac ?? 0, 4)}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {FormatDecimal(stockItem?.pricePurchaseLast ?? 0, 2)}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {FormatDecimal((stockItem?.priceWac ?? 0) * (stockItem?.quantity ?? 0), 2)}
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                })}
                            </React.Fragment>
                        ))}
                        <TableRow>
                            <TableCell>Celkom</TableCell>
                            <TableCell align='right'></TableCell>
                            <TableCell align='right'></TableCell>
                            <TableCell align='right'>{FormatDecimal(stockValuation ?? 0, 2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </StyledTable>


            </Box>
        </Drawer >
    )
}

export default ProductsDetail;