import React, { useEffect, useState } from 'react';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { ProductStock, Warehouse, StockAvailability } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';

// Komponenty
import { Alert, Chip, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import ApartmentIcon from '@mui/icons-material/Apartment';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import PlaceIcon from '@mui/icons-material/Place';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledTable = styled(Table)(({ theme }) => ({
    '& tbody td': {
        padding: theme.spacing(0.2),
    },
    '& tbody th': {
        paddingY: theme.spacing(0.2),
        lineHeight: 1.1,
    },
    '& tbody tr:last-child td, & tbody tr:last-child th': {
        border: 0,
    },
}));

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateStockProps extends ProductCreateComponentProps {
    warehouses: Warehouse[];
    stockAvailabilities: StockAvailability[];
};

const ProductCreateStock = (props: ProductCreateStockProps) => {

    // Zmeny vo formulári
    const handleChangeStock = (warehouseId: number, property: string, value: any) => {
        props.setSource(prev => {
            const item: ProductStock = { ...prev?.stocks?.find(item => item.warehouseId === warehouseId), warehouseId: warehouseId, [property]: value };
            const next = { ...prev, stocks: [...prev?.stocks?.filter(item => !(item.warehouseId === warehouseId)) ?? [], item] };
            return next;
        });
    }

    // Skupiny skladov
    const [warehouseGroups, setWarehouseGroups] = useState<string[]>([]);

    // Automaticky získam zoznam skupín
    useEffect(() => setWarehouseGroups(Array.from(new Set(props.warehouses?.map(item => item.warehouseGroupName ?? '') ?? []))), [props.warehouses]);

    // Upozornenie na limity
    const stockLimitWarning = (min?: number, max?: number, quantity?: number): boolean => {
        return ((min ?? 0) > 0 && (quantity ?? 0) < (min ?? 0)) ||
            ((max ?? 0) > 0 && (quantity ?? 0) > (max ?? 0));
    }

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <StyledTable size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '18%' }}>Množstvo</TableCell>
                                    <TableCell sx={{ width: '18%' }}>Dostupnosť</TableCell>
                                    <TableCell sx={{ width: '12%' }}>Rezervované</TableCell>
                                    <TableCell sx={{ width: '12%' }}>Objednané</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>Zásoby celkom</strong>
                                        {stockLimitWarning(props.source.stockMinimalQuantity, props.source.stockMaximalQuantity, props.source.stockQuantity) === true && <Chip label="dosiahnutý limit" color="error" size="small" sx={{ ml: 2 }} />}
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled
                                            value={props.source.stockQuantity ?? ''} placeholder='0'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ApartmentIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <Select fullWidth size="small" labelId="product-create-availability" value={props.source.stockAvailabilityId ?? 0} name="stockAvailabilityId" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}>
                                            <MenuItem value={0}>-</MenuItem>
                                            {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                            value={props.source.stockReservedQuantity ?? ''} placeholder='0'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <HistoryToggleOffIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                            value={props.source.stockOrderedQuantity ?? ''} placeholder='0'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <HistoryToggleOffIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                </TableRow>

                                {warehouseGroups?.map((groupName, index) => (
                                    <React.Fragment key={index}>
                                        {props.warehouses?.filter(stock => (stock.warehouseGroupName ?? '') === groupName)?.map((stock, index) => {
                                            var stockItem = props.source.stocks?.find(item => item.warehouseId === stock.id);
                                            return (
                                                <React.Fragment key={stock.id}>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {groupName.length > 0 ? groupName + ' / ' : ''}
                                                            {stock.name}
                                                            {stockLimitWarning(stockItem?.minimalQuantity, stockItem?.maximalQuantity, stockItem?.quantity) === true && <Chip label="dosiahnutý limit" color="error" size="small" sx={{ ml: 2 }} />}
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField disabled={(stock.type ?? 0) === 0} fullWidth size="small" name="quantity" type="text" variant="outlined" autoComplete="off"
                                                                value={stockItem?.quantity ?? ''} placeholder='0'
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {stock.type === 0 ? <ApartmentIcon /> : <EmojiTransportationIcon />}
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select fullWidth size="small" name="stockAvailabilityId"
                                                                value={stockItem?.stockAvailabilityId ?? 0}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), e.target.name, e.target.value) }}>
                                                                <MenuItem key={0} value={0}>-</MenuItem>
                                                                {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                                                value={stockItem?.reservedQuantity ?? ''} placeholder='0'
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <HistoryToggleOffIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off" disabled sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                                                value={stockItem?.orderedQuantity ?? ''} placeholder='0'
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <HistoryToggleOffIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </StyledTable>

                        <Alert severity='info' sx={{ mt: 2 }}>
                            <strong>Rezervované</strong> = sumár položiek (rezervované - dodané) z <u>nevybavených predajných</u> objednávok príslušného skladu.<br />
                            <strong>Objednané</strong> = sumár položiek (objednané - dodané) z <u>nevybavených nákupných</u> objednávok príslušného skladu.
                        </Alert>
                    </div>
                </div>
            </Paper>

            <Paper sx={{ p: 2, mt: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <StyledTable size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '36%' }}>Umiestnenie na sklade (pozícia)</TableCell>
                                    <TableCell sx={{ width: '12%' }}>Min. zostatok</TableCell>
                                    <TableCell sx={{ width: '12%' }}>Max. zostatok</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Zásoby
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="stockLocation" type="text" variant="outlined" autoComplete="off"
                                            value={props.source.stockLocation ?? ''}
                                            onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <PlaceIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="stockMinimalQuantity" type="text" variant="outlined" autoComplete="off" sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                            value={props.source?.stockMinimalQuantity ?? ''}
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ChevronRightIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="stockMaximalQuantity" type="text" variant="outlined" autoComplete="off" sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                            value={props.source?.stockMaximalQuantity ?? ''}
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ChevronLeftIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                </TableRow>

                                {warehouseGroups?.map((groupName, index) => (
                                    <React.Fragment key={index}>
                                        {props.warehouses?.filter(stock => (stock.warehouseGroupName ?? '') === groupName)?.map((stock, index) => {
                                            var stockItem = props.source.stocks?.find(item => item.warehouseId === stock.id);
                                            return (
                                                <React.Fragment key={stock.id}>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {groupName.length > 0 ? groupName + ' / ' : ''}
                                                            {stock.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField fullWidth size="small" name="location" type="text" variant="outlined" autoComplete="off"
                                                                value={stockItem?.location ?? ''}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), e.target.name, e.target.value) }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <PlaceIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField fullWidth size="small" name="minimalQuantity" type="text" variant="outlined" autoComplete="off" sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                                                value={stockItem?.minimalQuantity ?? ''}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <ChevronRightIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField fullWidth size="small" name="maximalQuantity" type="text" variant="outlined" autoComplete="off" sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                                                value={stockItem?.maximalQuantity ?? ''}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <ChevronLeftIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                ))}

                            </TableBody>
                        </StyledTable>
                    </div>
                </div>
            </Paper>

        </>
    )
};

export default ProductCreateStock;
