import { useHistory } from "react-router-dom";
import { AppConfig } from '../AppConfig';
import axios from 'axios';

// Modely
import { DocumentType, DocumentTypeStockUsages, ExternalDocumentType } from '../models/Models';

// Ikony
import StoreIcon from '@mui/icons-material/Store';
import DescriptionIcon from '@mui/icons-material/Description';
import WebIcon from '@mui/icons-material/Web';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ExploreIcon from '@mui/icons-material/Explore';
import AppsIcon from '@mui/icons-material/Apps';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ChatIcon from '@mui/icons-material/Chat';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import BuildIcon from '@mui/icons-material/Build';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import PublicIcon from '@mui/icons-material/Public';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ImageIcon from '@mui/icons-material/Image';
import EmailIcon from '@mui/icons-material/Email';
import SubjectIcon from '@mui/icons-material/Subject';
import EuroIcon from '@mui/icons-material/Euro';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import SourceIcon from '@mui/icons-material/Source';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import PrintIcon from '@mui/icons-material/Print';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InputIcon from '@mui/icons-material/Input';
import FitbitIcon from '@mui/icons-material/Fitbit';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import WorkIcon from '@mui/icons-material/Work';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InventoryIcon from '@mui/icons-material/Inventory';
import SwapVertIcon from '@mui/icons-material/SwapVert';

// Podstránky
import { AppRouteUrl } from '../AppRoutes';
import Navigation, { Item } from '../components/Navigation';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from "react";

// Zoznam položiek menu 
const Items = (): Item[] => [
    { path: AppRouteUrl.HOME, title: 'Prehľad', icon: <LeaderboardOutlinedIcon />, divider: true },
    {
        path: AppRouteUrl.ORDERS, title: 'Obchod', icon: <ShoppingCartCheckoutOutlinedIcon />,
        items: []
    },
    //  { path: AppRouteUrl.CASHDESK, title: 'Pokladňa', icon: <StoreIcon /> },
    {
        path: AppRouteUrl.PRODUCTS, title: 'Sklad', icon: <BusinessIcon />,
        items: [
            { path: AppRouteUrl.PRODUCTS, title: 'Produkty', icon: <InventoryIcon /> },
            { path: AppRouteUrl.STOCK_MOVEMENTS, title: 'Pohyby', icon: <SwapVertIcon /> },
            // { path: AppRouteUrl.PRODUCTS, title: 'Príjemky', icon: <AddCircleIcon /> },
            // { path: AppRouteUrl.PRODUCTS, title: 'Výdajky', icon: <RemoveCircleIcon /> }
        ]
    },
    { path: AppRouteUrl.CUSTOMERS, title: 'Adresár', icon: <GroupOutlinedIcon />, divider: true },
    { path: AppRouteUrl.FILES, title: 'Súbory', icon: <FilePresentOutlinedIcon />, divider: true },
    {
        path: AppRouteUrl.WEB_STRUCTURE, title: 'Web', icon: <WebIcon />, divider: true,
        items: [
            { path: AppRouteUrl.WEB_STRUCTURE, title: 'Navigácia', icon: <ExploreIcon /> },
            { path: AppRouteUrl.WEB_PAGES, title: 'Stránky', icon: <VerticalSplitIcon /> },
            {
                path: AppRouteUrl.ARTICLES, title: 'Obsah', icon: <AppsIcon />,
                items: [
                    { path: AppRouteUrl.ARTICLES, title: 'Textové články', icon: <SubjectIcon /> },
                    { path: AppRouteUrl.GALLERIES, title: 'Galérie', icon: <PhotoLibraryIcon /> },
                    { path: AppRouteUrl.SLIDESHOWS, title: 'Prezentácie', icon: <SlideshowIcon /> },
                    { path: AppRouteUrl.FORMS, title: 'Formuláre', icon: <DynamicFormIcon /> },
                    { path: AppRouteUrl.POPUPS, title: 'Vyskakovacie okná', icon: <FeedbackIcon /> }
                ]
            }
        ]
    },
    {
        path: AppRouteUrl.STATISTICS_ORDERS, title: 'Doplnky', icon: <FitbitIcon />, divider: true,
        items: [
            {
                path: AppRouteUrl.STATISTICS_ORDERS, title: 'Marketing', icon: <EmojiObjectsIcon />,
                items: [
                    // {
                    //     path: AppRouteUrl.STATISTICS_ORDERS, title: 'Štatistiky', icon: <BarChartIcon />,
                    //     items: [
                    //         { path: AppRouteUrl.STATISTICS_ORDERS, title: 'Objednávky', icon: <ShoppingCartIcon /> },
                    //         { path: AppRouteUrl.STATISTICS_PROFIT, title: 'Obrat a získ', icon: <TrendingUpIcon /> },
                    //         { path: AppRouteUrl.STATISTICS_VISITORS, title: 'Návštevnosť', icon: <PeopleOutlineIcon /> },
                    //         { path: AppRouteUrl.STATISTICS_CUSTOMERS, title: 'Zákazníci', icon: <PeopleAltIcon /> },
                    //         { path: AppRouteUrl.STATISTICS_PRODUCTS, title: 'Produkty', icon: <AutoAwesomeMotionIcon /> }
                    //     ]
                    // },
                    {
                        path: AppRouteUrl.LOYALTY_SYSTEM, title: 'Bonusový systém', icon: <CardGiftcardIcon />,
                        items: [
                            { path: AppRouteUrl.LOYALTY_SYSTEM, title: 'Vernostný program', icon: <LoyaltyIcon /> },
                            { path: AppRouteUrl.VOLUME_DISCOUNTS, title: 'Objemové zľavy', icon: <ControlPointDuplicateIcon /> },
                            { path: AppRouteUrl.VOUCHERS, title: 'Zľavové kupóny', icon: <ClosedCaptionIcon /> },
                            { path: AppRouteUrl.GIFTS, title: 'Darčeky zdarma', icon: <CardGiftcardIcon /> }
                        ]
                    },
                    // { path: AppRouteUrl.SHOPPING_CARTS, title: 'Nákupné košíky', icon: <ShoppingBasketIcon /> },
                    // { path: AppRouteUrl.REVIEWS, title: 'Hodnotenie a recenzie', icon: <ThumbUpIcon /> },
                    // { path: AppRouteUrl.WATCHDOGS, title: 'Strážne psy', icon: <NotificationsActiveIcon /> }
                ]
            },
            // {
            //     path: AppRouteUrl.POHODA, title: 'Prepojenie', icon: <CloudDoneIcon />,
            //     items: [
            //         { path: AppRouteUrl.POHODA, title: 'Pohoda', icon: <ExtensionIcon /> },
            //         { path: AppRouteUrl.MONEY, title: 'Money', icon: <ExtensionIcon /> },
            //         { path: AppRouteUrl.SMS, title: 'SMS brána', icon: <ChatIcon /> }
            //     ]
            // },
            {
                path: AppRouteUrl.IMPORT, title: 'Import / export', icon: <ImportExportIcon />,
                items: [
                    { path: AppRouteUrl.IMPORT, title: 'Automatický import', icon: <EventRepeatIcon />, subHeader: 'Import' },
                    { path: AppRouteUrl.IMPORT_QUEUE, title: 'Stav importu', icon: <PublishedWithChangesIcon /> },
                    { path: AppRouteUrl.EXPORT, title: 'Automatický export', icon: <EventRepeatIcon />, subHeader: 'Export' },
                    { path: AppRouteUrl.EXPORT_QUEUE, title: 'Stav exportu', icon: <PublishedWithChangesIcon /> },
                ]
            },
        ]
    },
    {
        path: AppRouteUrl.USERS, title: 'Nastavenia', icon: <SettingsOutlinedIcon />,
        items: [
            {
                path: AppRouteUrl.USERS, title: 'Základné', icon: <BuildIcon />,
                items: [
                    { path: AppRouteUrl.USERS, title: 'Užívatelia', icon: <PersonIcon /> },
                    { path: AppRouteUrl.USER_ROLES, title: 'Užívateľské role', icon: <VerifiedUserIcon /> },
                    { path: AppRouteUrl.LANGUAGES, title: 'Jazyky', icon: <GTranslateIcon /> },
                    { path: AppRouteUrl.CURRENCIES, title: 'Meny', icon: <MonetizationOnIcon /> },
                    { path: AppRouteUrl.COUNTRIES, title: 'Krajiny', icon: <PublicIcon /> },
                    { path: AppRouteUrl.EMAIL_ACCOUNTS, title: 'Emailové účty', icon: <EmailIcon /> },
                    { path: AppRouteUrl.DOMAINS, title: 'Domény', icon: <LanguageIcon /> },
                ]
            },
            {
                path: AppRouteUrl.TYPES_OF_DOCUMENTS, title: 'Firma', icon: <WorkIcon />,
                items: [
                    { path: AppRouteUrl.BRANCHES, title: 'Prevádzky (pobočky)', icon: <BusinessIcon /> },
                    { path: AppRouteUrl.DEPARTMENTS, title: 'Strediská (oddelenia)', icon: <HomeWorkIcon /> },
                ]
            },
            {
                path: AppRouteUrl.TYPES_OF_DOCUMENTS, title: 'Obchod', icon: <StoreIcon />,
                items: [
                    { path: AppRouteUrl.TYPES_OF_DOCUMENTS, title: 'Druhy a stavy dokladov', icon: <SourceIcon /> },
                    { path: AppRouteUrl.TYPES_OF_EXTERNAL_DOCUMENTS, title: 'Druhy externých dokladov', icon: <SourceIcon /> },
                    { path: AppRouteUrl.CUSTOMER_GROUPS, title: 'Zákaznícke skupiny', icon: <GroupsIcon /> },
                    { path: AppRouteUrl.PRICE_LISTS, title: 'Cenníky', icon: <EuroIcon /> },
                    { path: AppRouteUrl.PRICE_GROUPS, title: 'Cenové skupiny', icon: <EuroIcon /> },
                    { path: AppRouteUrl.SHIPMENTS, title: 'Dopravy', icon: <LocalShippingIcon /> },
                    { path: AppRouteUrl.PAYMENTS, title: 'Platby', icon: <CreditCardIcon /> },
                    { path: AppRouteUrl.NUMBER_SEQUENCE, title: 'Číslovanie dokladov', icon: <PlusOneIcon /> },
                    { path: AppRouteUrl.VAT_RATES, title: 'Sazdby DPH', icon: <AccountBalanceIcon /> },
                ]
            },
            {
                path: AppRouteUrl.PRODUCT_PARAMETERS, title: 'Sklad', icon: <WarehouseIcon />,
                items: [
                    { path: AppRouteUrl.PRODUCT_PARAMETERS, title: 'Parametre produktov', icon: <ColorLensIcon /> },
                    { path: AppRouteUrl.UNITS_OF_MEASURE, title: 'Merné jednotky', icon: <SquareFootIcon /> },
                    { path: AppRouteUrl.AVAILABILITY, title: 'Dostupnosť', icon: <CheckIcon /> },
                    { path: AppRouteUrl.WAREHOUSES, title: 'Sklady', icon: <WarehouseIcon /> },
                    { path: AppRouteUrl.TAGS, title: 'Štítky', icon: <LocalOfferIcon /> },
                ]
            },
            {
                path: AppRouteUrl.TEMPLATES_OF_PRINTING, title: 'Šablóny', icon: <PrintIcon />,
                items: [
                    { path: AppRouteUrl.TEMPLATES_OF_PRINTING, title: 'Šablóny pre tlač', icon: <PrintIcon /> },
                    { path: AppRouteUrl.TEMPLATES_OF_EMAIL, title: 'Šablóny pre emaily', icon: <AttachEmailIcon /> },
                    { path: AppRouteUrl.TEMPLATES_OF_SMS, title: 'Šablóny pre SMS', icon: <ChatIcon /> },
                ]
            },
            {
                path: AppRouteUrl.WATERMARK, title: 'Extra', icon: <KeyboardCommandKeyIcon />,
                items: [
                    // { path: AppRouteUrl.AUTOMATION, title: 'Automatické služby', icon: <AltRouteIcon /> },
                    { path: AppRouteUrl.CUSTOM_TEXTS, title: 'Vlastné texty', icon: <TextFieldsIcon /> },
                    { path: AppRouteUrl.CUSTOM_FIELDS, title: 'Vlastné polia', icon: <InputIcon /> },
                    { path: AppRouteUrl.WATERMARK, title: 'Vodoznak', icon: <ImageIcon /> },
                ]
            },
        ]
    }
];

// Vstupné parametre
interface SidebarProps {
    open: boolean;
    onClickToggle?: () => void;
}

const Sidebar = (props: SidebarProps) => {
    const history = useHistory();

    // Načítam zoznam druhov interných a externých dokladov, pre ktoré vygenerujem trasovanie
    const [items, setItems] = useState<Item[]>([...Items()]);
    useEffect(() => {
        axios
            .get(AppConfig.ApiUri + 'info/sidebar')
            .then(response => {

                const documentsSale: Item[] = [];
                const documentsPurchase: Item[] = [];
                const documentsExternal: Item[] = [];
                const documentsStock: Item[] = [];

                // Doklady
                if (response.data.documenttype) {
                    const types = response.data.documenttype as DocumentType[];
                    types.forEach(item => {
                        if ((item.stock ?? DocumentTypeStockUsages.None) === DocumentTypeStockUsages.Inbound ||
                            (item.stock ?? DocumentTypeStockUsages.None) === DocumentTypeStockUsages.Outbound) {
                            documentsStock?.push({
                                path: AppRouteUrl.DOCUMENTS + (item.id ?? 0).toString() + '/',
                                title: item.other?.translationName ?? '',
                                icon: (item.sale ?? false) === true ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />,
                                argument: 'Sklad',
                                divider: false
                            });
                            return;
                        }
                        if ((item.sale ?? false) === true) {
                            documentsSale?.push({
                                path: AppRouteUrl.DOCUMENTS + (item.id ?? 0).toString() + '/',
                                title: item.other?.translationName ?? '',
                                icon: <DescriptionIcon />,
                                argument: 'Predaj',
                                divider: false
                            });
                            return;
                        }
                        if ((item.sale ?? false) === false) {
                            documentsPurchase?.push({
                                path: AppRouteUrl.DOCUMENTS + (item.id ?? 0).toString() + '/',
                                title: item.other?.translationName ?? '',
                                icon: <DescriptionOutlinedIcon />,
                                argument: 'Nákup',
                                divider: false
                            });
                            return;
                        }
                    });
                }

                // Externé doklady
                if (response.data.externaldocumenttype) {
                    const types = response.data.externaldocumenttype as ExternalDocumentType[];
                    types.forEach(item => {
                        documentsExternal?.push({
                            path: AppRouteUrl.EXTERNAL_DOCUMENTS + (item.id ?? 0).toString() + '/',
                            title: item.other?.translationName ?? '',
                            icon: <InsertDriveFileOutlinedIcon />,
                            argument: 'Externé',
                            divider: false
                        });
                    });
                }

                if (documentsSale.length > 0) {
                    documentsSale[0].subHeader = 'Predaj';
                }
                if (documentsPurchase.length > 0) {
                    documentsPurchase[0].subHeader = 'Nákup';
                }
                if (documentsExternal.length > 0) {
                    documentsExternal[0].subHeader = 'Externé';
                }

                const changed = [...Items()];
                changed[1].items = [
                    ...documentsSale,
                    ...documentsPurchase,
                    ...documentsExternal,
                ];
                changed[2].items = [
                    ...Items()[2].items ?? [],
                    ...documentsStock
                ];

                setItems(changed);
            });
    }, []);

    const handleAutocomplete = (e: object, v: any | null) => {
        if (v?.path) {
            history.push(v.path);
        }
    };
    const autocompleteItems: any[] = [];
    const autocompleteItemsMap = (parent: string, e: Item[]) => {
        e.forEach((item) => {
            // Musia byť unikátne, inak to nebude fungovať (vypínam filter, kvôli rýchlosti mali by byť aj tak unikátne)
            // if(!autocompleteItems.find((exist) => exist.label === item.title)){
            if (item.items?.length) {
                autocompleteItemsMap((parent.length ? parent + ' / ' : '') + item.title, item.items);
            }
            else {
                autocompleteItems.push({ label: (parent.length ? parent + ' / ' : '') + (item.argument !== undefined ? item.argument + ' / ' : '') + item.title, path: item.path });
            }
            //}
        });
    };
    autocompleteItemsMap('', items);

    return (
        <>
            {(props.open &&
                <Autocomplete
                    disablePortal
                    sx={{ m: '0px 10px' }}
                    size="small"
                    getOptionLabel={option => option.label}
                    options={autocompleteItems}
                    isOptionEqualToValue={(option, value) => {
                        return option.path === value.path;
                    }}
                    value={null}
                    onChange={handleAutocomplete}
                    renderInput={(params) => <TextField {...params} label="Hľadať" />} />
            )}
            <Navigation items={items} onClickToggle={props.onClickToggle} />
        </>
    )
}

export default Sidebar;