import { useEffect, useState } from 'react'

// Utility
import { FormatDecimal, FormatCurrency } from '../../utility/Format';

// Modely
import { Currency, DocumentItem, DocumentItemTypes, DocumentTypeStockUsages } from '../../models/Models';
import { DocumentCreateComponentProps } from './DocumentCreate';

// Komponenty
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid';
import { MathRound2, MathRound4 } from '../../utility/Number';

// Predpis pre sumár    
interface Summary {
    priceTotalNoVat: number;
    priceTotalVatAmount: number;
    priceTotalPayed: number;
    priceStockTotal: number;
    weightTotal: number;
    unitsCount: number,
    itemsCount: number,
    productsCount: number,
    items: SummaryItem[];
}
interface SummaryItem {
    priceVatRate: number;
    priceTotalNoVat: number;
    priceTotalVatAmount: number;
}

// Vstupné parametre
interface DocumentCreateSummaryProps extends DocumentCreateComponentProps {
    currencies: Currency[];
}

const DocumentCreateSummary = (props: DocumentCreateSummaryProps) => {

    // Skladový pohyb (príjem / výdaj)
    const isStockMovement = (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Inbound || (props.documentType.stock ?? 0) === DocumentTypeStockUsages.Outbound;

    // Stav
    const [summary, setSummary] = useState<Summary>({
        priceTotalNoVat: 0,
        priceTotalVatAmount: 0,
        priceTotalPayed: 0,
        priceStockTotal: 0,
        weightTotal: 0,
        unitsCount: 0,
        itemsCount: 0,
        productsCount: 0,
        items: []
    });

    // Automatický prepočet celkového sumáru
    useEffect(() => {
        const summaryNext: Summary = {
            priceTotalNoVat: 0,
            priceTotalVatAmount: 0,
            priceTotalPayed: 0,
            priceStockTotal: 0,
            weightTotal: 0,
            unitsCount: 0,
            itemsCount: 0,
            productsCount: 0,
            items: []
        };

        // Rekurzívne zostavím zoznam položiek z ktorých získavam celkový sumár (položky, ktoré majú byť ignorované majú nulovú jednotkovú cenu, takže prevalím všetko)
        const sourceItems: DocumentItem[] = [];
        const sourceItemsAppend = (items: DocumentItem[]) => {
            items.forEach(item => {
                sourceItems.push(item);
                sourceItemsAppend(item.items ?? []);
            });
        }
        sourceItemsAppend(props.source?.items ?? []);

        // Spočítam sumár v jednotlivých sadzbách podľa nastavenia
        props.vatRates.sort((a, b) => a < b ? 1 : -1).forEach(vatRate => {
            // DPH počítať pri každej položke
            // Výpočet: DPH sa vypočíta pri každej položke a následne je rekapitulácia dokladu len súčet týchto položiek, rovnako ako pri registračnej pokladnici.
            if (props.vatCalculationMethod === 0) {
                summaryNext.items.push({
                    priceVatRate: vatRate,
                    priceTotalNoVat: (sourceItems.filter(item => item.priceUnitVatRate === vatRate)?.reduce((sum, current) => sum + (current?.priceTotalNoVat ?? 0), 0) ?? 0),
                    priceTotalVatAmount: (sourceItems.filter(item => item.priceUnitVatRate === vatRate)?.reduce((sum, current) => sum + (current?.priceTotalVatAmount ?? 0), 0) ?? 0)
                });
            }

            // DPH počítať z celkovej sumy
            // DPH sa vypočíta z celkovej sumy s DPH v danej sadzbe a zaokrúhli sa na dve desatiny. Následne sa vypočíta celková suma bez DPH odpočítaním vypočítanej DPH od celkovej sumy s DPH.
            if (props.vatCalculationMethod === 1) {
                // Získam celkovú cenu s DPH
                let priceTotalNoVat = (sourceItems.filter(item => item.priceUnitVatRate === vatRate)?.reduce((sum, current) => sum + (current?.priceTotalNoVat ?? 0), 0) ?? 0);
                let priceTotalVatAmount = (sourceItems.filter(item => item.priceUnitVatRate === vatRate)?.reduce((sum, current) => sum + (current?.priceTotalVatAmount ?? 0), 0) ?? 0);
                let priceTotalWithVat = priceTotalNoVat + priceTotalVatAmount;

                // Vypočítam hodnotu DPH a zaokrúhlim na 2 desatiny a následne použijem pre výpočet celkovej ceny bez DPH, ktorú tiež zaokrúhlim na 2 desatiny
                priceTotalVatAmount = MathRound2((priceTotalWithVat * vatRate) / (100 + vatRate));
                priceTotalNoVat = MathRound2(priceTotalWithVat - priceTotalVatAmount);

                summaryNext.items.push({
                    priceVatRate: vatRate,
                    priceTotalNoVat: priceTotalNoVat,
                    priceTotalVatAmount: priceTotalVatAmount
                });
            }
        });

        // Spočítam celkovú sumu bez DPH a výšku samotnej DPH
        summaryNext.priceTotalNoVat = (summaryNext?.items?.reduce((sum, current) => sum + (current?.priceTotalNoVat ?? 0), 0) ?? 0);
        summaryNext.priceTotalVatAmount = (summaryNext?.items?.reduce((sum, current) => sum + (current?.priceTotalVatAmount ?? 0), 0) ?? 0);
        summaryNext.priceTotalPayed = (props.source.payments?.reduce((sum, current) => sum + (current?.price ?? 0), 0) ?? 0);
        summaryNext.priceStockTotal = MathRound4(sourceItems?.reduce((sum, current) => sum + (current?.priceStock ?? 0) * (current?.quantity ?? 0), 0) ?? 0);
        summaryNext.weightTotal = MathRound4(sourceItems?.reduce((sum, current) => sum + (current?.weight ?? 0) * (current?.quantity ?? 0), 0) ?? 0);
        summaryNext.unitsCount = (sourceItems?.filter(item => item.type === DocumentItemTypes.Product)?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0) ?? 0);
        summaryNext.itemsCount = sourceItems?.length ?? 0;
        summaryNext.productsCount = (sourceItems?.filter(item => item.type === DocumentItemTypes.Product)?.length ?? 0);

        // Použijem sumár
        setSummary(summaryNext);

        // Doplním celkový sumár do objednávky
        props.setSource(prev => ({
            ...prev,
            priceTotalNoVat: summaryNext.priceTotalNoVat,
            priceTotalVatAmount: summaryNext.priceTotalVatAmount,
            priceStockTotal: summaryNext.priceStockTotal,
        }));
    }, [props.source.items, props.vatRates, props.vatCalculationMethod]); // eslint-disable-line react-hooks/exhaustive-deps

    // Cudzia mena (ak už neexistuje, tak kód meny použijem ako zadný symbol)
    let foreignCurrency: Currency = {};
    if (props.source.currencyCode !== '' && props.source.currencyCode !== props.currency.code) {
        foreignCurrency = props.currencies.find(currency => currency.code === props.source.currencyCode) ?? { surfix: props.source.currencyCode };
    }

    return (
        <Grid container columnSpacing={1}>
            <Grid item xs={12}>

                <Table size="small" sx={{ '& tr:last-child td, & tr:last-child th': { border: 0 } }}>
                    <TableBody>
                        {(props.source.currencyCode ?? '') !== '' && props.source.currencyCode !== props.currency.code && (
                            <TableRow>
                                <TableCell sx={{ pl: 0 }}>Kurz</TableCell>
                                <TableCell sx={{ pr: 0 }} align="right">{FormatCurrency(1, props.currency, 0)} = {FormatCurrency((props.source.currencyExchange ?? 1), foreignCurrency, 4)}</TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell sx={{ pl: 0 }}>Hmotnosť</TableCell>
                            <TableCell sx={{ pr: 0 }} align="right">{summary.weightTotal > 1000 ? (summary.weightTotal / 1000) + ' kg' : summary.weightTotal + ' g'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ pl: 0 }}>Množstvo MJ</TableCell>
                            <TableCell sx={{ pr: 0 }} align="right">{FormatDecimal(summary.unitsCount)} mj</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ pl: 0 }}>Produktov</TableCell>
                            <TableCell sx={{ pr: 0 }} align="right">{summary.productsCount}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ pl: 0 }}>Položiek</TableCell>
                            <TableCell sx={{ pr: 0 }} align="right">{summary.itemsCount}</TableCell>
                        </TableRow>
                        {isStockMovement === true && (
                            <TableRow>
                                <TableCell sx={{ pl: 0 }}>Skladová cena</TableCell>
                                <TableCell sx={{ pr: 0 }} align="right">{FormatCurrency(summary.priceStockTotal, props.currency, 2)}</TableCell>
                            </TableRow>
                        )}
                        {(props.documentType.stock ?? 0) === DocumentTypeStockUsages.Outbound && (
                            <TableRow>
                                <TableCell sx={{ pl: 0 }}>Zisk</TableCell>
                                <TableCell sx={{ pr: 0 }} align="right">{FormatCurrency(summary.priceTotalNoVat - summary.priceStockTotal, props.currency, 2)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                <Table size="small" sx={{ mt: 2, '& tr:last-child td, & tr:last-child th': { border: 0 } }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 0 }}><strong>Sadzba</strong></TableCell>
                            <TableCell align="right"><strong>Základ</strong></TableCell>
                            <TableCell align="right"><strong>DPH</strong></TableCell>
                            <TableCell align="right" sx={{ pr: 0 }}><strong>Spolu</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {summary?.items?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ pl: 0 }}><strong>{item.priceVatRate} %</strong></TableCell>
                                <TableCell align="right">{FormatCurrency(item.priceTotalNoVat, props.currency)}</TableCell>
                                <TableCell align="right">{FormatCurrency(item.priceTotalVatAmount, props.currency)}</TableCell>
                                <TableCell sx={{ pr: 0 }} align="right">{FormatCurrency((item.priceTotalNoVat + item.priceTotalVatAmount), props.currency)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell sx={{ pl: 0 }}><strong>Celkom</strong></TableCell>
                            <TableCell align="right"><strong>{FormatCurrency(summary.priceTotalNoVat, props.currency)}</strong></TableCell>
                            <TableCell align="right"><strong>{FormatCurrency(summary.priceTotalVatAmount, props.currency)}</strong></TableCell>
                            <TableCell sx={{ pr: 0 }} align="right"><strong>{FormatCurrency((summary.priceTotalNoVat + summary.priceTotalVatAmount), props.currency)}</strong></TableCell>
                        </TableRow>
                        {/* Celkom v cudzej mene */}
                        {(props.source.currencyCode ?? '') !== '' && props.source.currencyCode !== props.currency.code && (
                            <>
                                <TableRow>
                                    <TableCell sx={{ pl: 0 }}></TableCell>
                                    <TableCell align="right"><strong>{FormatCurrency((summary.priceTotalNoVat * (props.source.currencyExchange ?? 1)), foreignCurrency, 2)}</strong></TableCell>
                                    <TableCell align="right"><strong>{FormatCurrency((summary.priceTotalVatAmount * (props.source.currencyExchange ?? 1)), foreignCurrency, 2)}</strong></TableCell>
                                    <TableCell sx={{ pr: 0 }} align="right"><strong>{FormatCurrency(((summary.priceTotalNoVat + summary.priceTotalVatAmount) * (props.source.currencyExchange ?? 1)), foreignCurrency, 2)}</strong></TableCell>
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>

            </Grid>
        </Grid>
    )
}

export default DocumentCreateSummary;