import { FormEvent, useEffect, useState } from 'react';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';

// Modely

// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import PercentIcon from '@mui/icons-material/Percent';

// Vstupné parametre
export interface DocumentCreateItemsDiscountProps {
    discount?: number;
    open: boolean;
    onSave: (discount?: number) => void;
    onClose: () => void;
}

const DocumentCreateItemsDiscount = (props: DocumentCreateItemsDiscountProps) => {

    // Stav
    const [discount, setDiscount] = useState<number | undefined>(props.discount);

    // Automatický reset
    useEffect(() => {
        if (!props.open) {
            setDiscount(undefined);
        }
        setDiscount(props.discount);
    }, [props.discount, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(discount);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
            setTimeout(() => { input?.select(); }, 250);
        }
    }

    return (
        <>
            <Dialog keepMounted={false} maxWidth="xs" fullWidth open={props.open} scroll="body" onClose={(_e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Zľava na položku
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <TextField fullWidth required margin="dense" name="price" type="text" label={'Výška zľavy (%)'} variant="outlined" autoComplete="off" value={discount ?? ''} onChange={(e) => { setDiscount(ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDiscount(undefined)} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DocumentCreateItemsDiscount;