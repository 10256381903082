import { useEffect, useState } from 'react'

// Modely
import { CustomerBase, Country, Customer } from '../../models/Models';

// Komponenty
import CustomerBaseEdit, { CustomerBaseEditProps } from './CustomerBaseEdit';
import CustomerBaseInfo from './CustomerBaseInfo';

export interface CustomerBaseComponentProps {
    customer: CustomerBase;
    countries?: Country[];
    keepMounted?: boolean;
    startAction?: CustomerBaseComponentAction;
    startActionCallback?: () => void;
    onSave?: (customerBase: CustomerBase, customer?: Customer) => void; // pri uložení sa vráti upravená trieda 'customerBase' ale aj aktuálna trieda 'customer' z adresára (načítava sa vždy po otvorení okna)
}

// Možnosti pre vyvolanie rôznych akcií
export enum CustomerBaseComponentAction {
    Edit,
    Select
}

// Slúži na zlúčenie kopomonentu pre zobrazenie základných vlastností zákazníka a komponentu pre ich editáciu
const CustomerBaseComponent = (props: CustomerBaseComponentProps) => {

    // Editácia
    const [orderCreateCustomer, setOrderCreateCustomer] = useState<CustomerBaseEditProps>({
        customer: props.customer ?? {},
        countries: [],
        open: false,
        keepMounted: props.keepMounted,
        onSave: (customerBase, customer) => {
            if (props.onSave) {
                props.onSave(customerBase, customer);
            }
        },
        onClose: () => setOrderCreateCustomer(prev => ({ ...prev, open: false }))
    });
    const handleCustomerEdit = (openCustomers?: boolean) => {
        setOrderCreateCustomer(prev => ({
            ...prev,
            open: true,
            openCustomers: openCustomers ?? false
        }));
    };

    // Automatické akcie
    useEffect(() => {
        if (props.startAction === undefined) {
            return;
        }
        if (props.startAction === CustomerBaseComponentAction.Edit) {
            handleCustomerEdit(false);
        }
        if (props.startAction === CustomerBaseComponentAction.Select) {
            handleCustomerEdit(true);
        }
        props.startActionCallback?.();
    }, [props.startAction]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Výpis údajov */}
            <CustomerBaseInfo customer={props.customer ?? {}} onCustomerEdit={(props.onSave !== undefined ? handleCustomerEdit : undefined)} countries={props.countries} />

            {/* Úprava údajov (ak je definovaná udalosť po zmene) */}
            {props.onSave !== undefined && <CustomerBaseEdit {...orderCreateCustomer} customer={props.customer} countries={props.countries} />}
        </>
    )
}

export default CustomerBaseComponent;