import { useState } from 'react';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import StockMovements from './StockMovements';

// Modely
import { StockMovementLite, StockMovementsFilter, StockMovementType } from '../../models/Models';

// Vstupné parametre
export interface StockMovementsDialogProps {
    open: boolean;
    argument?: any; //môžem uchovať pomocné dočasné info pri otovrení okna, ktoré získam po zavretí
    keepMounted?: boolean;
    filter?: StockMovementsFilter;
    onSelect?: (StockMovements: StockMovementLite[], argument?: any) => void;
    onClose?: () => void;
}

const StockMovementsDialog = (props: StockMovementsDialogProps) => {

    // Označené produkty
    const [selected, setSelected] = useState<StockMovementLite[]>([]);

    // Zmena výberu s možnosťou potvrdenia výberu
    const handleSelectionChanged = (items: StockMovementLite[], confirm: boolean) => {
        if (confirm) {
            handleConfirm(items); // Pri potvrdení rovno odovzdám aktuálny zoznam, nemôžem čakať na zmenu stavu
        } else {
            setSelected(items); // Zmením aktuálny výber
        }
    };

    // Potvrdenie výberu (nepoužívam zoznam zo stavu keďže zmnena stavu sa môže dokončiť neskôr ako príde potvrdenie)
    const handleConfirm = (items: StockMovementLite[]) => {
        if (props?.onSelect) {
            props?.onSelect(items, props.argument);
        }
    };

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick' && props.onClose) { props.onClose(); } }}>
                <DialogContent sx={{ height: '87vh' }}>
                    <StockMovements onSelectionChanged={handleSelectionChanged} filter={props.filter} filterDisableAutosave={true} />
                </DialogContent>
                <DialogActions sx={{ pt: 0 }}>
                    <Button onClick={props.onClose}>Späť</Button>
                    {props.onSelect !== undefined && (
                        <Button onClick={() => handleConfirm(selected)} type="submit" variant="contained">Potvrdiť výber</Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default StockMovementsDialog;
