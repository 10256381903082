import { useState } from 'react';

// Modely
import { WebPage, WebPageBlockModuleTypes, WebPageBlock, WebPageBlockModules } from '../../models/Models';

// Komponenty
import { Alert, Button, MenuItem, FormControlLabel, Switch, Accordion, AccordionDetails, AccordionSummary, Typography, Menu, IconButton, Chip, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import WebPageCreateContent, { WebPageCreateContentProps } from './WebPageCreateContent';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface WebPageCreateBlocksProps {
    source: WebPage;
    area: number;
    areaTitle: string;
    setSource: (action: React.SetStateAction<WebPage>) => void;
    isAccordionExpanded: (name: string) => boolean;
    onToggleAccordion: (name: string) => void;
};

const WebPageCreateBlocks = (props: WebPageCreateBlocksProps) => {

    // Stav
    const [addMenuEl, setAddMenuEl] = useState<null | HTMLElement>(null);
    const [webPageCreateContent, setWebPageCreateContent] = useState<WebPageCreateContentProps>({
        open: false,
        module: WebPageBlockModuleTypes.None,
        onSelect: (content: number) => {
            // Zavriem okno a vnútorne vyvolám vloženie vybranej položky, vnútorne preto, aby som získal modul z properties
            setWebPageCreateContent(prev => {
                handleItemAdd(prev.module, content);
                return ({ ...prev, open: false });
            });
        },
        onClose: () => setWebPageCreateContent(prev => ({ ...prev, open: false }))
    });

    // Pridať položku
    const handleItemCreate = (moduleType: WebPageBlockModuleTypes) => {
        // Ak modul nemá možnosť výberu obsahu, rovno ho pridám do bloku
        if ((WebPageBlockModules.find(module => module.type === moduleType)?.selectable ?? false) === false) {
            handleItemAdd(moduleType, 0);
            return;
        }
        // Zobrazim zoznam s obsahom
        setWebPageCreateContent(prev => ({ ...prev, module: moduleType, open: true }));
    };

    // Pridať položku - obsah
    const handleItemAdd = (moduleType: WebPageBlockModuleTypes, content: number) => {
        props.setSource(prev => ({
            ...prev, blocks: [...prev.blocks ?? [], {
                id: 0,
                area: props.area,
                ordinalNumber: ((prev.blocks?.filter(block => block.area === props.area)?.length ?? 0) > 0 ? (Math.max(...(prev.blocks?.filter(block => block.area === props.area)?.map(i => i.ordinalNumber ?? 0) ?? [])) ?? 0) + 1 : 0),
                module: moduleType,
                content: content,
                showOnAllPages: false
            }]
        }));
    };

    // Vymazať položku
    const handleItemDelete = (item: WebPageBlock) => {
        props.setSource(prev => {
            return { ...prev, blocks: (prev.blocks ?? []).filter(prevItem => prevItem !== item) };
        });
    };

    // Upraviť položku
    const handleItemChange = (item: WebPageBlock, property: string, value: any) => {
        props.setSource(prev => {
            let changed = prev.blocks ?? [];
            for (var index = 0; index < changed.length; index++) {
                if (changed[index] === item) {
                    changed[index] = { ...changed[index], [property]: value }
                }
            }
            return { ...prev, blocks: changed };
        });
    };

    // Vygeneruje tlačidlo
    const createAddButon = (type: WebPageBlockModuleTypes) => {
        return <MenuItem onClick={() => { handleItemCreate(type); setAddMenuEl(null); }}>{WebPageBlockModules.find(module => module.type === type)?.title ?? ''}</MenuItem>;
    }

    // Zoradenie položiek, najskôr podľa poradia potom podľa id súboru (poradie môže byť rovnaké)
    const blocksSortComparer = (a: WebPageBlock, b: WebPageBlock): number => {
        if ((a.ordinalNumber ?? 0) !== (b.ordinalNumber ?? 0)) {
            return (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1;
        }
        else {
            return (a.content ?? 0) > (b.content ?? 0) ? 1 : -1;
        }
    }

    // Celkový počet
    const areaCount: number = (props.source?.blocks?.filter(block => block.area === props.area).length ?? 0);

    return (
        <>
            {/* Výber obsahu */}
            <WebPageCreateContent {...webPageCreateContent} />

            <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('block' + props.area)} onChange={() => { props.onToggleAccordion('block' + props.area) }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{props.areaTitle} {areaCount > 0 && '(' + areaCount + ')'}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container columnSpacing={1}>

                        <Grid item xs={12}>
                            <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" aria-label="Vložiť" aria-controls="menu-add" aria-haspopup="true" onClick={(e) => setAddMenuEl(e.currentTarget)}>Vložiť</Button>
                        </Grid>

                        <Menu id="menu-add" anchorEl={addMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '35px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(addMenuEl)} onClose={() => setAddMenuEl(null)} PaperProps={{ style: { maxHeight: 250 } }}>
                            {createAddButon(WebPageBlockModuleTypes.Article)}
                            {createAddButon(WebPageBlockModuleTypes.Articles)}
                            {createAddButon(WebPageBlockModuleTypes.Gallery)}
                            {createAddButon(WebPageBlockModuleTypes.Slideshow)}
                            {createAddButon(WebPageBlockModuleTypes.Form)}
                            {createAddButon(WebPageBlockModuleTypes.Menu)}
                            {createAddButon(WebPageBlockModuleTypes.Registration)}
                            {createAddButon(WebPageBlockModuleTypes.Login)}
                            {createAddButon(WebPageBlockModuleTypes.Categories)}
                            {createAddButon(WebPageBlockModuleTypes.Products)}
                            {createAddButon(WebPageBlockModuleTypes.Orders)}
                            {createAddButon(WebPageBlockModuleTypes.Cart)}
                            {createAddButon(WebPageBlockModuleTypes.Dynamic)}
                        </Menu>

                        {/* Zoznam */}
                        {
                            props.source?.blocks?.filter(item => item.area === props.area)?.sort((a, b) => blocksSortComparer(a, b))?.map((item, index) => {
                                return (
                                    <Grid item xs={12} mt={1} key={index}>
                                        <Alert
                                            variant="standard"
                                            severity="info"
                                            icon={false}
                                            action={
                                                <>
                                                    <FormControlLabel sx={{ mt: 0.4 }} control={<Switch checked={item.showOnAllPages} size="small" name="showOnAllPages" onChange={(e) => { handleItemChange(item, e.target.name, e.target.checked); }} />} label={
                                                        <Tooltip title="Vybraný blok sa bude zobrazovať na všetkých stránkach v rámci rovnakej domény">
                                                            <Typography variant="body2" className="formIconHelp">Všetky stránky</Typography>
                                                        </Tooltip>} />
                                                    <Chip label={'#' + (item.ordinalNumber ?? 0)} sx={{ mr: 1 }}></Chip>
                                                    <IconButton daria-label="Nahor" title="Nahor" size="small" onClick={(e) => { handleItemChange(item, 'ordinalNumber', (item.ordinalNumber ?? 0) - 1); }}>
                                                        <KeyboardArrowUpIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton daria-label="Nadol" title="Nadol" size="small" onClick={(e) => { handleItemChange(item, 'ordinalNumber', (item.ordinalNumber ?? 0) + 1); }}>
                                                        <KeyboardArrowDownIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton aria-label="Upraviť" size="small" target="_blank"
                                                        disabled={(WebPageBlockModules.find(module => module.type === (item.module ?? 0) as WebPageBlockModuleTypes)?.editableUrl ?? '') === ''}
                                                        href={(WebPageBlockModules.find(module => module.type === (item.module ?? 0) as WebPageBlockModuleTypes)?.editableUrl ?? '') + '?id=' + (item.content ?? 0).toString()}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton aria-label="Vymazať" color="inherit" size="small" onClick={() => handleItemDelete(item)}>
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </>
                                            }>
                                            <strong>{WebPageBlockModules.find(module => module.type === item.module)?.title ?? ''}</strong> {(item.content ?? 0) > 0 && ' (ID: ' + (item.content ?? 0) + ')'}<br />
                                        </Alert>
                                    </Grid>
                                )
                            })
                        }

                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default WebPageCreateBlocks;