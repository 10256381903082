import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../../AppRoutes';

// Modely
import { Warehouse } from '../../../models/Models';

// Utility
import { ResponsivePoint as HidePoint } from '../../../utility/ResponsivePoint';
import { FindText } from '../../../utility/Search';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../../utility/DataGrid';
import { useQuery } from '../../../utility/URL';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Button, Divider, Grid, IconButton, ListItemIcon, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import Search from '../../../components/Search';
import WarehouseCreate, { WarehouseCreateProps } from './WarehouseCreate';
import { Content, ContentTop, ContentBottom } from '../../../layout/Content';
import DataGridDensity from '../../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

// Komponent pre zoznam 
const Warehouses = () => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [warehouseCreate, setWarehouseCreate] = useState<WarehouseCreateProps>({
        open: false,
        keepMounted: true,
        onSave: () => loadData(),
        onClose: () => setWarehouseCreate(prev => ({ ...prev, open: false }))
    });

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'warehouses' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'name', headerName: 'Názov', hide: false, minWidth: 50, flex: 0.8 },
        { field: 'warehouseGroupName', headerName: 'Skupina', hide: false, minWidth: 50, flex: 0.5 },
        { field: 'address', headerName: 'Adresa', hide: false, minWidth: 50, flex: 0.5 },
        { field: 'branchName', headerName: 'Prevádzka', hide: HidePoint().lg, minWidth: 50, flex: 0.5 },
        { field: 'customerName', headerName: 'Účtovná jednotka', hide: true, minWidth: 50, flex: 0.5 },
        { field: 'type', headerName: 'Typ', hide: false, minWidth: 50, flex: 0.3, valueFormatter: (params: GridValueFormatterParams) => (params?.value as number) === 0 ? 'Vlastný sklad' : 'Externý sklad' },
        { field: 'ordinalNumber', headerName: 'P.č.', hide: HidePoint().md, minWidth: 50, flex: 0.2, align: 'center', headerAlign: 'center' },
        {
            field: 'createdDate', headerName: 'Vytvorený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => (params?.value as Date).toLocaleDateString() ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Kopírovať" title="Kopírovať (ctrl + enter)" size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<Warehouse[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<Warehouse[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setWarehouseCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.WAREHOUSES);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'warehouse/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'warehouse/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: columnsSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'warehouse')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <WarehouseCreate {...warehouseCreate} />

            {/* Obsah */}
            <Content>
                <ContentTop>

                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={12} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nový sklad</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }}
                                autoFocus={true} />
                        </Grid>
                    </Grid>

                </ContentTop>
                <ContentBottom>

                    {/* Zoznam */}
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rowsFiltered}
                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleCreate(e.row.id, false, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, e.row.name);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>

                </ContentBottom>
            </Content>
        </>
    )
}

export default Warehouses;